@import '../variables';

.NppPagination__wrapper {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  justify-content: space-between;
  padding: 0.571rem 0.143rem 0.571rem 1.429rem;

  .MuiPagination-ul .MuiButtonBase-root {
    margin: 0;
  }

  .MuiPagination-ul .MuiButtonBase-root svg {
    font-size: 2.4rem;
  }

  .NppPagination__select {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $gray-600;
  }

  .NppDropdown__wrapper {
    margin-right: 2rem;
  }

  .NppDropdown__wrapper .MuiButtonBase-root {
    font-size: $font-size-small;
    padding-left: 10px;
    padding-right: 10px;
  }

  .NppPagination__control {
    display: flex;
    gap: 1rem;

    button {
      padding: 0;
      height: auto;
      min-width: auto;
    }

    button p {
      height: 2.5rem;
    }

    button i {
      font-size: 1rem;
      color: $blue-50 !important;
    }

    button {
      color: $blue-50 !important;
      background-color: transparent;
    }

    button:hover,
    button:active,
    button:focus {
      color: $blue-50;
      background-color: $bg-gray-color-2;
    }

    button:disabled,
    button.disabled,
    button.Mui-disabled i{
      color: $black-color !important;
      opacity: .4;
    }
  }
}

.NppPagination__dropdown-menu .MuiMenu-list {
  min-width: 6rem;
}

.NppPagination__without-row-per-page {
  justify-content: flex-end;
}
