.detail-account-page {

  .card {
    margin-bottom: 0;

    & + .card {
      margin-top: .5rem;
    }

    .small {
      color: #42526E;
    }
  }

  h2 {
    .badge {
      font-size: .75rem;
      margin-left: .5rem;
    }
  }

  .account-icon {
    color: black;
    font-size: 2.5rem;
  }

  .account-info {
    span {

      & + span {
        border-left: 1px solid $primary-3;
        margin-left: .5rem;
        padding-left: .5rem;
      }
    }
  }

  .info-list {
    @extend .list-unstyled;
    font-size: .875rem;

    li {
      & + li {
        border-top: 1px solid #F2F4F6;
      }

      span {
        display: inline-block;
        line-height: 2.5rem;
      }

      span:first-child {
        font-weight: $font-weight-bold;
        min-width: 11rem;
      }

      span:last-child {
        color: #42526E;
      }

      .link {
        display: inline-block;
        float: right;
        line-height: 2.5rem;
      }
    }

    .info-title {
      font-weight: $font-weight-bold;
      min-width: 11rem;
    }
  }

  i {
    font-style: normal;
  }
}