.connectwise-settings-page {

  .select-input {
    margin-bottom: 1.5em;

    .form-group {
      margin-bottom: 0;
    }
  }
}

.connectwise-page {

  .empty_state {
    margin-left: 30%;
    margin-right: 30%;
  }
}