@import '../variables';

.NppDropdown__wrapper {
  .MuiButtonBase-root,
  .MuiButtonBase-root i {
    color: $primary-color;
    font-family: $font-family;
    font-size: 0.714rem;
  }

  button:focus {
    outline: unset;
  }
}

.NppDropdown__disable-button {
  background-color: $mute-color-4;
}

.NppDropdown__menu {
  .MuiMenu-list {
    min-width: 23.214rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .MuiMenuItem-root {
    font-size: $font-size-default;
    color: $primary-color;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .MuiMenuItem-divider {
    border-bottom: $thin-border;
  }
}

.NppDropdown__menu-item {
  display: block;
  max-height: 200px;
  position: relative;
  overflow-y: auto;
  /* Ensure this is set to allow scrolling */
  overflow-anchor: none;
  touch-action: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
}

.NppMenuItem__with-danger-color {
  .MuiTypography-root {
    color: $primary-color;
  }
}

.NppDropdown__button {
  .MuiButtonBase-root {
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 2px 6px 0px #0000000a;
    border-radius: 4px;
    background-color: $white;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .MuiTypography-root {
    font-weight: $font-weight-semi-bold;
    // font-size: 1rem;
  }

  .MuiButtonBase-root i {
    font-size: 0.85rem;

    &::before {
      margin: 0;
      display: flex;
    }
  }

  i.organizationfilter__icon {
    color: $blue-50;
  }

  .MuiButtonBase-root i.line-partner {
    font-size: 1.2rem;

    &::before {
      margin: 0;
      display: flex;
    }
  }
}
