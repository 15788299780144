//	================================================================================================
//	File Name: components-variables.scss
//	Description: Custom theme specific variables and other content variations
//	----------------------------------------------------------------------------------------------
//	Item Name: Stack - Responsive Admin Theme
//	Author: PIXINVENT
//	Author URL: http://www.themeforest.net/user/pixinvent
//	================================================================================================

//  ================================================================================================
//  WARNING: PLEASE DO NOT CHANGE THIS VARIABLE FILE.
//  THIS FILE WILL GET OVERWRITTEN WITH EACH STACK TEMPLATE RELEASE.
//  TIP:
//  We suggest you to use this (assets/scss/variables/components-variables.scss) file for overriding app variables.
//  ================================================================================================


// Bootstrap  variables
@import "../../bootstrap/variables";
@import "variables";

$base-font-size: 14px;
$body-direction: ltr; // Default ltr, change it to rtl for Right To Left support.

//	------------------------------
//	  Colors
//	------------------------------
$content-bg: #F5F7FA; 
$border-light: #ddd;
$transparent: transparent;
$swiper-bg: #f2f4f4;
$hover-color: #f2f4f4;

//	------------------------------
//	  Buttons
//	------------------------------
$btn-border-radius-square: 0;
$btn-border-radius-round: 2rem;

//	------------------------------
//	  Navbar
//	------------------------------
$navbar-height:4rem;
$navbar-light-bg: $white;
$navbar-dark-bg: #404E67;
$navbar-header-width : 240px;

//	------------------------------
//	  Main Menu
//	------------------------------


//main menu light
$menu-light-bg: $white;
$menu-light-color: $gray-600;

//main menu dark
$menu-dark-bg: #404E67;
$menu-dark-color: #dcdcdc;

$menu-padding : 10px 30px 10px 14px;
$menu-second-level-padding : 8px 18px 8px 54px;
$menu-third-level-padding : 8px 18px 8px 64px;
$menu-forth-level-padding : 8px 18px 8px 74px;

// vertical menu
$menu-expanded-width: 240px;
$menu-collapsed-width: 60px;

// vertical overlay menu
$overlay-menu-width: 240px;

//Main menu footer
$menu-footer-height: 15px;
$menu-footer-color: $white;
$menu-footer-color-bg: #404E67;
$menu-footer-link-bg: rgb(33, 41, 46);
$menu-footer-link-hover-bg: rgb(30, 36, 39);

//Footer color
$footer-color-light-bg: $white;
$footer-color-dark-bg : #404E67;

//	------------------------------
//	  Sideber
//	-------------------------------
$sidebar-width: 300px;
//	-------------------------------
//	  Avatar
//	-------------------------------

$avatar-size: 32px;
$avatar-status-size: 11px;

$avatar-size-xl: 70px;
$avatar-status-size-xl: 20px;

$avatar-size-lg: 50px;
$avatar-status-size-lg: 15px;

$avatar-size-sm: 24px;
$avatar-status-size-sm: 7px;

$avatar-status-online-color: $success;
$avatar-status-offline-color: $gray-100;
$avatar-status-busy-color: $danger;
$avatar-status-away-color: $warning;


//	-------------------------------
//	Form
//	-------------------------------

// $input-height, $input-height-lg, $input-height-sm are in variables
$font-size-xs: .75rem;
$font-size-xl: ($font-size-base + 0.50);
$line-height-xl: 1.7;
$line-height-xs: 1.5;
$input-padding-y-xl: 0.5rem !default;
$input-padding-x-xl: 0.5rem !default;

$input-padding-y-xs: 0.2rem !default;
$input-padding-x-xs: 0.275rem !default;

$border-radius-xl: .35rem !default;
$border-radius-xs: .12rem !default;
$input-border-radius-xl: $border-radius-xl;
$input-border-radius-xs: $border-radius-xs;

$input-height-xl: (($font-size-xl * $line-height-xl) + ($input-padding-y-xl * 2)) !default;
$input-height-xs: (($font-size-xs * $line-height-xs) + ($input-padding-y-xs * 2)) !default;


//	-------------------------------
//	Table
//	-------------------------------
$table-border-color: #e3ebf3;

//  Kanban App
$kanban-board-bg: #e7edf3;
$kanban-sidebar-label: #bac0c7;

// Todo Application
$todo-filters-color: #bac0c7;
$todo-star-color: #c7cfd6;
$todo-avatar-bg: #C3C3C3;
$todo-border-color: #E4E7ED;

// App Chat
$app-chat-message-bg: #fafbfb;
$chat-badge-bg: #f3f8fd;
$chat-sidebar-width: 300px;

// Email Application
$selected-email-bg: #e7edf3;
$detail-mail-bg: #fafbfb;
$favorite-star-color: #c7cfd6;
$email-menu-expanded-width: 300px;