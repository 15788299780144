@import "../variables";

.DataTableSection__content-wrapper {
    display: grid;
    grid-row-gap: 30px;
    font-family: sans-serif;
  }

  .MuiPaper-root .DataTableSection__table-wrapper {
    padding: 0.857rem 0rem 0rem;

    .DataTableSection__table-header {
      margin: 0rem 1.429rem 0.857rem 1.429rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        font-size: 0.857rem !important;
      }
    }

    .DataTableSection__table {
      .MuiTableCell-root .NppLink__wrapper {
        font-weight: $font-weight-semi-bold;
      }
    }

    .DataTableSection__table-button {
      padding: 0.857rem 0rem 0rem 0.857rem;
      text-align: center;
    }
  }