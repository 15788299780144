.organization-settings-page{
  .organization-settings-card{
    .card-header{
      border-bottom: $primary-1;
    }

    .organization-settings-card-content{
      padding: 1.5rem 1.25rem;

      .solid-alert-question{
        color: $blue-50;
        font-size: 0.8rem;
      }

      .btn-primary-outline {
        background-color: transparent;
        border-color: $blue-50;
        color: $blue-50;
      }

      .image-alert {
        color: $red-50;
        font-size: 0.8571rem;
      }

      a {
        font-size: $font-size-base;
      }

      label {
        font-size: 1rem;
      }

      .aspect-ratio-box {
        position: relative;
        max-width: 136px;
        min-height: 75px;
        max-height: 136px;
        align-items: center;
        display: flex;
      }

      .aspect-ratio-box img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 100%;
        max-height: 136px;
      }

      .remove-btn {
        color: $red-50;
        background-color: transparent;
        border-style: none;
      }

      .file-not-found {
        background-color: $primary-2;
        height: 7.5rem;
        text-align: center;
        padding-top: 1.8rem;
      }

      /* Form Group CSS */
      .form-group {
        .floating-right-icon{
          position: relative;
          float: right;
          right: 1rem;
          bottom: 3.5rem;
        }
      }

      .form-check {
        padding: 0;

        label {
          font-size: $font-size-base;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: 0;
          opacity: 0.01;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 1.5rem;
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25rem;
          height: 1.25rem;
          background: $primary-1;
          border: 0.06rem solid #E4E9ED;
          box-sizing: border-box;
          border-radius: 0.12rem;
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: "\2713";
          position: absolute;
          top: 0;
          left: 0;
          color: $blue-50;
          text-align: center;
          display: table;
          line-height: 1.15rem;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
        }

        [type="checkbox"]:checked + label:after {
          opacity: 1;
          width: 1.25rem;
          height: 1.25rem;
          border: 0.06rem solid $blue-50;
          box-sizing: border-box;
          background: $blue-20;
          border-radius: 0.12rem;
          line-height: 1.15rem;
        }

        /* Disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #e9e9e9;
        }

        [type="checkbox"]:disabled:checked + label:after {
          color: #777;
        }

        [type="checkbox"]:disabled + label {
          color: #aaa;
        }

        /* Accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
        }
      }

      .form-content {
        padding: 0rem;
      }

      h3.title{
        font-size: 1.125rem;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        box-shadow: inset 0px -1px 0px #F2F4F6;
      }

      /* FilePond CSS */
      .filepond--drop-label {
        color: $blue-50;
        height: 7.0rem;

        label {
          color: $blue-50;
        }
      }

      .filepond--label-action {
        text-decoration-color: $blue-50;
      }

      .filepond--panel-root {
        border-radius: 0.2857rem;
        background-color: rgba(204, 227, 249, 0.3);
        height: 2em;
      }

      .filepond--item-panel {
        background-color: #595e68;
      }

      .filepond--drip-blob {
        background-color: #7f8a9a;
      }

      .filepond--root{
        border: 1px dashed #0075E1;
        height: 7.5rem;
        width: 100%;
      }
    }  
  }
}