@import '../variables';

.FormControl__default-wrapper {
  .MuiInputLabel-root {
    font-size: $font-size-default;
    font-family: 'Inter';
    position: initial;
    display: block;
    bottom: 0px;
  }

  .MuiInputBase-input {
    position: relative;
    border: 1px solid $border-form-color;
    width: auto;
    padding: .25rem .5rem;
    font-family: 'Inter';
    background-color: #f3f4f5;
    font-weight: $font-weight-default;
    color: $primary-color;
    font-family: 'Inter';
    border-radius: 0.21rem;
    font-size: .875rem;
    height: 1.8rem;
    line-height: 1;
  }

  .MuiInputBase-input.Mui-disabled,
  .MuiInputBase-input:disabled {
    background-color: $bg-gray-color-4 !important;
    border: 1px solid $bg-gray-color-3 !important;
    color: $black-color-2;
  }

  .MuiInputBase-input.focus,
  .MuiInputBase-input:focus {
    box-shadow: 0px 0px 0px 2px $bg-blue-focus !important;
    border-color: $bg-gray-color-3 !important;
  }

  .MuiInputBase-input.active,
  .MuiInputBase-input:active {
    color: $primary-color !important;
  }

  .Mui-error .MuiInputBase-input {
    border-color: $bg-icon-error !important;
  }

  .MuiInputAdornment-root {
    position: absolute;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .MuiInputBase-root {
    position: relative;
    z-index: 0;
    padding: 0;
    width: 100%;
  }

  .MuiInputBase-input.MuiInputBase-inputAdornedStart {
    padding-left: 4rem;
  }

  .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
    padding-right: 4rem;
  }

  .MuiInputAdornment-positionEnd {
    right: 1rem;
  }

  .MuiInputAdornment-positionStart {
    left: 1rem;
    z-index: 1;
  }

  fieldset {
    display: none;
  }

  .MuiInputAdornment-root svg {
    font-size: $font-size-large;
    color: $black-color;
  }

  .MuiInputAdornment-root.NppTextField__password-visible-icon svg {
    color: $primary-color;
    cursor: pointer;
  }
}

// text field styles
.FormControl__default-wrapper {
  .NppTextField__label {
    color: $black-color;
    margin-bottom: 0.3rem;
    font-weight: $font-weight-semi-bold;
  }

  .NppTextField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .NppTextField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;

  }

  .NppTextField__error-text {
    font-size: $font-size-small;
    color: $bg-icon-error;
    margin-top: .6rem;
  }

  .NppTextField__helper-text svg,
  .NppTextField__error-text svg {
    font-size: 1.3rem;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }
}

// select field styles
.FormControl__default-wrapper {
  .NppSelectField__label {
    color: $black-color;
    margin-bottom: 0.3rem;
    font-size: $font-size-default;
  }

  .NppSelectField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .NppSelectField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;

  }

  .NppSelectField__error-text {
    font-size: $font-size-small;
    color: $bg-icon-error;
  }

  .NppSelectField__helper-text svg,
  .NppSelectField__error-text svg {
    font-size: 1.3rem;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }

  .NppSelectField__select .MuiInputBase-input {
    width: 100%;
  }

  .NppSelectField__select .MuiSelect-nativeInput {
    display: none;
  }

  .NppSelectField__select .MuiInputAdornment-positionEnd {
    right: 2.5em;
  }

  .NppSelectField__select .MuiSelect-icon {
    font-size: $font-size-large;
    color: $primary-color;
  }

  .NppSelectField__select .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
    padding-right: 5.5rem;
  }

  .NppSelectField__select .MuiSelect-select {
    line-height: 1.9rem;
  }

  .NppSelectField__select .MuiInputAdornment-root svg {
    font-size: $font-size-large;
  }
}

.FormControl__default-wrapper.FormControl__horizontal-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  &>.MuiInputBase-root {
    min-width: 27%
  }
}

.FormControl__default-wrapper.FormControl__full-width-wrapper {
  width: 100%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.NppFormGroup__wrapper {
  gap: 2.6rem;
  display: flex;
  flex-direction: column;
}