@font-face {
  font-family: 'dropsuite-solid';
  src: url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.eot?37976237');
  src: url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.eot?37976237#iefix') format('embedded-opentype'),
       url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.woff2?37976237') format('woff2'),
       url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.woff?37976237') format('woff'),
       url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.ttf?37976237') format('truetype'),
       url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.svg?37976237#dropsuite-solid') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dropsuite-solid';
    src: url('../fonts/dropsuite-solid-icon/font/dropsuite-solid.svg?37976237#dropsuite-solid') format('svg');
  }
}
*/

[class^="solid-"]:before, [class*=" solid-"]:before {
  font-family: "dropsuite-solid";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  //opacity: .8;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  font-size: 120%;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  //text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

[class^="solid-"], [class*=" solid-"] {
  display: inline-block;

  &.medium {
    font-size: 1.2rem;
  }

  &.large {
    font-size: 1.5rem;
  }

  &.larger {
    font-size: 1.75rem;
  }
}

.solid-warning:before { content: '\e800'; } /* '' */
.solid-search:before { content: '\e801'; } /* '' */
.solid-user:before { content: '\e802'; } /* '' */
.solid-chevron-left:before { content: '\e803'; } /* '' */
.solid-chevron-right:before { content: '\e804'; } /* '' */
.solid-chevron-up:before { content: '\e805'; } /* '' */
.solid-chevron-down:before { content: '\e806'; } /* '' */
.solid-national-grm:before { content: '\e807'; } /* '' */
.solid-national-prt:before { content: '\e808'; } /* '' */
.solid-national-frn:before { content: '\e809'; } /* '' */
.solid-national-pol:before { content: '\e80a'; } /* '' */
.solid-national-spn:before { content: '\e80b'; } /* '' */
.solid-national-ned:before { content: '\e80c'; } /* '' */
.solid-national-vtm:before { content: '\e80d'; } /* '' */
.solid-national-jpn:before { content: '\e80e'; } /* '' */
.solid-national-us:before { content: '\e80f'; } /* '' */
.solid-national-id:before { content: '\e810'; } /* '' */
.solid-users:before { content: '\e811'; } /* '' */
.solid-cloud:before { content: '\e812'; } /* '' */
.solid-sharepoint:before { content: '\e813'; } /* '' */
.solid-task:before { content: '\e814'; } /* '' */
.solid-calendar:before { content: '\e815'; } /* '' */
.solid-envelope:before { content: '\e816'; } /* '' */
.solid-phonebook:before { content: '\e817'; } /* '' */
.solid-filter:before { content: '\e818'; } /* '' */
.solid-save:before { content: '\e819'; } /* '' */
.solid-arrow-up:before { content: '\e81a'; } /* '' */
.solid-arrow-down:before { content: '\e81b'; } /* '' */
.solid-arrow-right:before { content: '\e81c'; } /* '' */
.solid-arrow-left:before { content: '\e81d'; } /* '' */
.solid-arrow-line-down:before { content: '\e81e'; } /* '' */
.solid-arrow-line-up:before { content: '\e81f'; } /* '' */
.solid-sort-table:before { content: '\e820'; } /* '' */
.solid-check:before { content: '\e821'; } /* '' */
.solid-alert-love:before { content: '\e822'; } /* '' */
.solid-alert-check:before { content: '\e823'; } /* '' */
.solid-refresh:before { content: '\e824'; } /* '' */
.solid-alert-info:before { content: '\e825'; } /* '' */
.solid-alert-warning:before { content: '\e826'; } /* '' */
.solid-alert-question:before { content: '\e827'; } /* '' */
.solid-alert-triangle:before { content: '\e828'; } /* '' */
.solid-migrate:before { content: '\e829'; } /* '' */
.solid-download-1:before { content: '\e82a'; } /* '' */
.solid-restore:before { content: '\e82b'; } /* '' */
.solid-backup:before { content: '\e82c'; } /* '' */
.solid-check-circle:before { content: '\e82d'; } /* '' */
.solid-add:before { content: '\e82e'; } /* '' */
.solid-close:before { content: '\e82f'; } /* '' */
.solid-upload:before { content: '\e830'; } /* '' */
.solid-download:before { content: '\e831'; } /* '' */
.solid-bell:before { content: '\e832'; } /* '' */
.solid-reseller:before { content: '\e833'; } /* '' */
.solid-more:before { content: '\e834'; } /* '' */
.solid-arrow-button-down:before { content: '\e835'; } /* '' */
.solid-arrow-button-up:before { content: '\e836'; } /* '' */
.solid-arrow-button-left:before { content: '\e837'; } /* '' */
.solid-arrow-button-right:before { content: '\e838'; } /* '' */
.solid-eye-slash:before { content: '\e839'; } /* '' */
.solid-eye:before { content: '\e83a'; } /* '' */
.solid-ticket:before { content: '\e83b'; } /* '' */
.solid-thumbs-down:before { content: '\e83e'; } /* '' */
.solid-thumbs-up:before { content: '\e83f'; } /* '' */
.solid-calc:before { content: '\e842'; } /* '' */
.solid-in:before { content: '\e87c'; } /* '' */
.solid-out:before { content: '\e87d'; } /* '' */
.solid-padlock-shield:before { content: '\e888'; } /* '' */
.solid-padlocked-shield:before { content: '\e889'; } /* '' */
.solid-pie:before { content: '\e88a'; } /* '' */
.solid-reseller-1:before { content: '\e90e'; } /* '' */
.solid-customer:before { content: '\e90f'; } /* '' */
