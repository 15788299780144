.MuiPaper-root .InfoCardBlock__card-info {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  gap: 0.714rem;

  .InfoCardBlock__card-info-left {
    i {
      font-size: 2.286rem;

      &::before {
        margin: 0rem;
      }
    }

    i.solid-padlock-shield {
      color: $danger-5;
    }

    i.solid-padlocked-shield {
      color: $success-6;
    }

    i.solid-pie {
      color: $danger-5;
    }
  }

  .InfoCardBlock__card-info-right {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
    }
  }
}