.HeaderPageBlock__wrapper {
  display: grid;
  grid-row-gap: 1.786rem;

  .HeaderPageBlock__back-button {
    margin-bottom: -2rem;
    color: $blue-50;

    i::before {
      margin: 0;
    }
  }

  .HeaderPageBlock__title {
    margin-bottom: 0.8rem;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 0.8rem;
  }
}