.accounts-page {
  .product-icon {
    color: $blue-50;
  }

  .org-filter-buttons {
    margin: 0 1rem;

    &:hover {
      color: $blue-50;
    }

    &.active {
      color: $blue-50;
    }
  }

  .reset-to-default-button {
    color: $blue-50;
    text-decoration: underline;
    text-decoration-color: $blue-50;

    &:hover {
      color: $blue-50;
    }
  }

  .pagination-button {
    color: $blue-50;
    text-decoration: underline;
    text-decoration-color: $blue-50;

    &:hover {
      color: $blue-50;
    }
  }
}