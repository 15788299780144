.NppCheckbox__wrapper {
  .MuiTypography-root {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-small;
    color: $primary-color;
  }
  
  .MuiCheckbox-root {
    svg {
      font-size: $font-size-large;
      color: $mute-color-2;
    }
  }

  .Mui-checked svg {
    color: $blue-color;
  }
}

.NppCheckbox__without-label .MuiFormControlLabel-root {
  margin: 0;
}
