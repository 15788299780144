.overview-support-page, .unified-overview-support-page {
  .status-bar,
  .page-content {
    font-size: .85rem;
  }

  .card {
    .card-header {
      border-bottom: 1px solid #F2F4F6;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }

    .card-body {
      padding-top: 0;
    }

    .loading {
      min-height: 215px;
    }

    .loading-small {
      .spinner-border {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .page-tabs {
    border-bottom: 1px solid #F2F4F6;

    .nav-link {
      span {
        display: none;
      }

      &.active {
        span {
          font-weight: $font-weight-bold;
          display: inline-block;
        }
      }

      i.line-warning {
        color: $red-50;
        margin-left: .25rem;
      }
    }
  }

  .info-list {
    margin-bottom: 0;
    min-height: 215px;

    li {
      span:first-child {
        font-weight: normal;
      }

      span:last-child {
        float: right;
      }
    }

    a {
      color: $body-color;
    }
  }
}