.media-list{
	.media{
		padding: 1.25rem;
		margin-top: 0;
		.media-left{
			padding-right: 1rem
		}
		.media-right{
			padding-left: 1rem;
		}
	}
	a.media{
		color: $gray-600 !important;		
	}
}

.media-bordered{
	.media{
		border-top: 1px solid #E4E7ED;
		&:first-child{
			border-top: 0;
		}
	}
}