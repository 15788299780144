.tfa-settings-page {
  .tfa-card {
    h3 {
      margin-bottom: 1rem;

      &:not(:first-of-type) {
        margin-top: 2rem;
      }
    }

    .tfa-status {
      margin: 1.5rem 0;
    }
    
    .status {
      color: $gray-500;

      &.active {
        color: $green-50;
      }
    }

    .appstore-links {
      margin: 1.5rem 0;
    }

    a.reset {
      color: $red-50;
      display: inline-block;
      margin-top: .3rem;
    }

    p {
      line-height: 1.78rem;
    }

    .btn-primary {
      margin-top: 1rem;
    }
  }
}

.modal-tfa {
  .modal-body {
    padding: 2rem 1.5rem;
  }

  .tfa-code {
    text-align: center;
    margin-bottom: 2.25rem;
  }

  .tfa-image-container {
    min-height: 232px;
    margin-bottom: 1rem;
  }

  input[readonly] {
    display: inline-block;
    width: 25rem;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  p {
    line-height: 1.78rem;
  }
}

.modal-tfa-reset,
.modal-tfa-enable,
.modal-tfa-disable {

  button {
    &:disabled {
      pointer-events: none;
    }

    &.btn-save {
      min-width: 8.5rem;
    }
  }
}