.resources-page {
  /* Form Group CSS */
  .form-check {
    padding: 0.3rem 0;

    label {
      font-size: $font-size-base;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 1.5rem;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      background: $primary-1;
      border: 0.06rem solid #E4E9ED;
      box-sizing: border-box;
      border-radius: 0.12rem;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "\2713";
      position: absolute;
      top: 0;
      left: 0;
      color: $blue-50;
      text-align: center;
      display: table;
      line-height: 1.15rem;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      width: 1.25rem;
      height: 1.25rem;
      border: 0.06rem solid $blue-50;
      box-sizing: border-box;
      background: $blue-20;
      border-radius: 0.12rem;
      line-height: 1.15rem;
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
      color: #777;
    }

    [type="checkbox"]:disabled + label {
      color: #aaa;
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
    }

    .sr-only {
      height: 1.5rem !important;
    }
  }

  .is-invalid {
    border-color: #DD252F;
    padding-right: 2.75rem;
    background-repeat: no-repeat;
    background-position: right calc(0.3125em + 0.375rem) center;
    background-size: calc(0.625em + 0.75rem) calc(0.625em + 0.75rem);
  }

  .invalid-feedback {
    display: block;
  }
}

.resources-detail-page, .resources-preview-page {
  .spinner-border.small {
    width: 1rem;
    height: 1rem;
  }

  .card-body .card-text{
    line-height: 1.8rem;

    blockquote {
      border-left: 2px solid #d3dae4;
      padding-left: 1em;
    }

    img {
      max-width: 100%;
    }
  }

  iframe {
    width: 100% !important;
    min-height: 315px;
    background-color: #DD252F;
  }
}