/**
* Default Value
**/

// Background
$bg-white-color: #fff !default;
$bg-gray-color: #e6eaf0 !default;
$bg-gray-color-1: #f7f9fc !default;
$bg-gray-color-2: #f2f4f6 !default;
$bg-gray-color-3: #d7dde4 !default;
$bg-gray-color-4: #e6e8ea !default;
$bg-gray-color-5: #d7dbe3 !default;
$bg-gray-color-6: #f7f9fc !default;
$bg-success: #e3fce9 !default;
$bg-success-disabled: rgba(16, 128, 0, 0.5) !default;
$bg-error: #fce3e6 !default;
$bg-error-2: #d51c25 !default;
$bg-icon-error: #dd3c51 !default;
$bg-icon-success: #25a742 !default;
$bg-icon-success-2: #0f7469 !default;
$bg-blue-color: #1c63d5 !default;
$bg-blue-color-2: #3678e3 !default;
$bg-blue-color-3: #002855 !default;
$bg-blue-focus: #1289f8 !default;
$bg-cyan-color: #e6effc !default;
$bg-warning: #faf5e1 !default;
$bg-danger: #fce3e4 !default;
$bg-primary: #e3edfc !default;
$bg-success-2: #e1faf7 !default;
$bg-info: #E3F0FC !default;

// Font
$primary-color: #1B2942 !default;
$primary-color-2: #002f7b !default;
$black-color: #2D3337 !default;
$black-color-2: #5f6f89 !default;
$black-color-3: #1D2431 !default;
$black-color-4: #3D4348 !default;
$mute-color: #42526E !default;
$mute-color-2: #9ea5b0 !default;
$mute-color-3: #97a3b8 !default;
$mute-color-4: #f0f2f6 !default;
$active-color: $bg-blue-color !default;
$white-color: #fff !default;
$blue-color: #1c63d5 !default;
$warning-color: #5e4d00 !default;
$warning-color-2: #887212 !default;
$danger-color: #7b0006 !default;
$danger-color-2: #c30a13 !default;
$danger-color-3: #d51c25 !default;
$danger-color-4: $bg-error-2 !default;
$danger-color-5: #d51c25 !default;
$success-color: #004d44 !default;
$tosca-color: #0f7469 !default;

$white: #fff;
$gray-100: #e9ebed; // $gray-lightest
$gray-200: #8f99a3; // $gray-lighter
$gray-300: #626e82; // $gray-light
$gray-400: #6f7274;
$gray-500: #adb5bd;
$gray-600: #404e67; // $gray
$gray-700: #4e5154;
$gray-800: #1b2942; // $gray-dark
$gray-900: #2a2e30;
$black: #000;

// Primary color gradient
$primary-1: #f3f4f5;
$primary-2: #e9ebed;
$primary-3: #c7ccd1;
$primary-4: #abb3ba;
$primary-5: #8f99a3;
$primary-6: #77838f;
$primary-7: #636d78;
$primary-8: #4f5760;
$primary-9: #3b4248;
$primary-10: #272c30;
$primary-11: #7d858d;

// Secondary color gradient
$secondary-1: #d3effb;
$secondary-2: #a7dff7;
$secondary-3: #7acff4;
$secondary-4: #4ebff0;
$secondary-5: #22afec;
$secondary-6: #139eda;
$secondary-7: #1087ba;
$secondary-8: #0d709b;
$secondary-9: #0b5a7c;
$secondary-10: #08445d;

// Danger color gradient
$danger-1: #f6dfe0;
$danger-3: #fb888e;
$danger-5: #dd252f;
$danger-6: #b9262e;
$danger-8: #881f25;
$danger-10: #612326;

// Warning color gradient
$warning-1: #ffdec1;
$warning-3: #ffda82;
$warning-5: #ffc844;
$warning-6: #e7a300;
$warning-8: #b98200;
$warning-10: #8a6200;

// Success color gradient
$success-1: #caefd2;
$success-3: #94dfa5;
$success-5: #36b252;
$success-6: #2e9946;
$success-8: #1f662f;
$success-10: #174c23;

// Blue color gradient
$blue-80: #072a4a;
$blue-70: #09457b;
$blue-60: #065dac;
$blue-50: #0075e1;
$blue-40: #399df9;
$blue-30: #8cc4f8;
$blue-20: #e3f0fc;

$red-80: #4a0711;
$red-70: #84101f;
$red-60: #ba1c31;
$red-50: #dd3c51;
$red-40: #ea7181;
$red-30: #f4a9b3;
$red-20: #fce3e6;

$yellow-80: #4a3707;
$yellow-70: #986e0b;
$yellow-60: #e2a208;
$yellow-50: #ffc333;
$yellow-40: #fbd270;
$yellow-30: #fae3ae;
$yellow-20: #fcf5e3;

$green-80: #074a16;
$green-70: #116925;
$green-60: #1a8933;
$green-50: #25a742;
$green-40: #5ec977;
$green-30: #a8dcb4;
$green-20: #e3fce9;

$blue: $blue-20; //$info
$red: $red-20; //$danger
$orange: $yellow-60; //$warning
$green: $green-20; //$success
$cyan: $blue-20; //$primary

$primary: $blue-50;
$info: $blue-50;
$warning: $yellow-60;
$secondary: $primary-9;
$success: $green-50;
$danger: $red-60;
$light: $gray-100;
$dark: $gray-800;

// border
$border-error: #f4a9b3 !default;
$border-error-2: $bg-error-2 !default;
$border-success: #a8dcb4 !default;
$border-success-2: #9cd9d3 !default;
$border-info: #9ea5b0 !default;
$border-info-2: #f0f2f6 !default;
$border-info-3: #8CC4F8 !default;
$border-color: $bg-gray-color !default;
$border-form-color: $bg-gray-color-5 !default;
$thin-border: 1px solid $border-color !default;

$border-warning: #ded3a0 !default;
$border-danger: #f9b4b8 !default;
$border-primary: #b4cff9 !default;

/**
* Typography
**/
$font-family: "Inter" !default;
$font-line-height-default: 24px !default;
$font-letter-spacing-default: 0.02px !default;
$font-color-default: $primary-color !default;

$font-size-base: 1rem !default;
$font-size-h1: $font-size-base * 2 !default;
$font-size-h2: $font-size-base * 1.428 !default;
$font-size-h3: $font-size-base * 1.285 !default;
$font-size-h4: $font-size-base * 1.142 !default;
$font-size-h5: $font-size-base !default;
$font-size-h6: $font-size-base * 0.857 !default;

$font-size-banner: 12rem !default;
$font-size-extra-large: 2.2rem !default;
$font-size-large: 1.8rem !default;
$font-size-default: $font-size-base !default;
$font-size-small: $font-size-base * 0.857 !default;
// $font-size-extra-small: 1rem !default;
$font-size-extra-small: 10px !default;
$font-size-icon-default: 2rem !default;

$font-weight-default: 400 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-size-heading-list: (
  h6: $font-size-h6,
  h5: $font-size-h5,
  h4: $font-size-h4,
  h3: $font-size-h3,
  h2: $font-size-h2,
  h1: $font-size-h1,
);

/**
* Wrapper
**/
$shadow-depth-10: 0px 1px 3px rgba(0, 0, 0, 0.1),
  0px 1px 2px rgba(0, 0, 0, 0.06) !default;
$shadow-depth-100: 0px 2px 4px rgba(0, 0, 0, 0.05),
  0px 7px 20px rgba(9, 14, 18, 0.1) !default;
$shadow-depth-1000: 0px 5px 20px rgba(0, 0, 0, 0.05),
  0px 15px 30px rgba(0, 0, 0, 0.15) !default;
$inset-shadow: inset 0rem -0.1rem 0rem $primary-1;
$end-shadow: inset -0.1rem 0rem 0rem $bg-gray-color-5;

$shadow-list: (
  depth-10: $shadow-depth-10,
  depth-100: $shadow-depth-100,
  depth-1000: $shadow-depth-1000,
);

// drawer height
$drawer-height-breadcrumbs: 4.4rem;
$drawer-height-heading: 5.6rem;
$drawer-height-bottom-bar: 6.4rem;

// others
$transition-default: all 0.2s ease !default;
