.billing-card {
  @extend .dashboard-card;

  .card-header {
    @extend .dashboard-card-header;
  }

  .card-content {
    .card-body {
      padding: 1rem 1.25rem;
    }
  }
}

.billing-page {
  .tabulator-row {
    &.tabulator-selectable:hover {
    }

    &.tabulator-selected:hover {
    }
  }
}

.billing-detail-page {
  h2 {
    .badge {
      font-size: 0.85714286rem;
      margin-left: .5rem;
    }
  }

  .address {
    margin-top: 1rem;

    > div {
      margin-bottom: .3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}