@import "../variables";

.Card_wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  padding: 0;
  // margin-bottom: 1.875rem;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07),
    0 2px 9px 0 rgba(62, 57, 107, 0.06) !important;
  width: 100%;

  .MuiCard-root {
    box-shadow: 0 5px 5px rgba(9, 14, 18, 0.03);
    color: #3b4248;
  }

  .MuiCardContent-root {
    padding: 0 !important;
  }

  .Card_header-wrapper {
    padding: 16px 20px 0px 20px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Card_header-left {
      .Card_header-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;

        i {
          color: $blue-50 !important;
          font-size: 0.857rem;
          margin-left: 0.143rem;
        }
      }

      .Card_header-description {
        padding: 4px 0px 16px 0px;
        color: #636d78;
        font-size: 12px;
      }
    }

    .Card_header-right {
      .Card_header-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;

        i {
          color: $blue-50 !important;
          font-size: 0.857rem;
          margin-left: 0.143rem;
        }
      }

      .Card_header-description {
        padding: 4px 0px 16px 0px;
        color: #636d78;
        font-size: 12px;
      }

      .Card_header-right-loading.hidden{
        visibility: hidden;
      }
    }

  }

  .Card_content-wrapper {
    padding: 16px 20px 16px 20px;
    align-items: center;
    flex-direction: column;
  }

  .Card_content-wrapper-loading {
    padding: 16px 20px 16px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .Card_footer-wrapper {
    padding: 16px 20px 16px 20px;
    bottom: 0;
    width: 100%;
    height: 54px;
    border-top: 1px solid #eeeeee;
    display: flex;
    align-items: center;

    .Card_footer-button {
      font-size: 12px;
      line-height: 22px;
      color: #0075e1;
    }

  }

  .align-left {
    display: flex;
    justify-content: flex-start;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }
}

.NppCardListItem__wrapper {
  gap: 2rem;

  .NppCardListItem__content {
    flex-grow: 1;
    gap: 0.35rem;
    display: flex;
    flex-direction: column;

    .NppCardListItem__info,
    .NppCardListItem__info-mute {
      font-size: $font-size-small;
      font-weight: $font-weight-default;
    }

    .NppCardListItem__info-mute {
      color: $mute-color-3;
    }
  }

  .NppCardListItem__left-content,
  .NppCardListItem__right-content {
    display: flex;
  }
}

.NppCardListItem__loading {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  .NppCardListItem__left-content {
    display: flex;
    align-self: baseline;
  }
}

.NppCardListItem__wrapper.NppCardListItem__compact-padding {
  padding: 1.3rem 1.6rem;
}