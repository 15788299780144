.modal.modal-support-details {
  .modal-body {
    padding: 1.5rem;
  }

  .info-list {
    font-size: 1rem;

    li {
      line-height: 3rem;

      span:last-child {
        float: right;
      }
    }
  }

  a.sub-product-details {
    &:after {
      content: '\f078' !important;
      font-family: "FontAwesome";
      position: relative;
      padding: 0 2px 0 6px;
      vertical-align: 0;
    }

    &.open:after {
      content: '\f077' !important;
    }
  }

  .well {
    background-color: $primary-1;
    color: $primary-7;

    p {
      margin-bottom: 0em;
    }

    a {
      font-size: 1.0em;
    }

    .pull-right{
      float: right;
    }
  }
}

.detailed-support-page, .unified-detailed-support-page {
  .tabulator-row {
    &.tabulator-selectable:hover {
      cursor: default;
    }

    &.tabulator-selected:hover {
      cursor: default;
    }
  }

  p {
    margin-bottom: 0em;
  }
}