@import "../variables";

.ReportingDashboard__wrapper {
  display: grid;
  grid-row-gap: 25px;

  .ReportingDashboard__title {
    margin-bottom: 0.8rem;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 0.8rem;
  }

  .ReportingDashboardBilling__card {
    justify-content: space-between;
  }
  .ReportingDashboardBilling__card-loading {
    justify-content: center;
  }

  .ReportingDashboardBilling__card,
  .ReportingDashboardBilling__card-loading {
    display: flex;
    flex-direction: row;

    .ReportingDashboardBilling__card-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.714rem;

      i {
        font-size: 2rem;
        color: $blue-50;

        &::before {
          margin: 0rem;
        }
      }

      .ReportingDashboardBilling__card-description {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .ReportingDashboard__content-wrapper {
    display: grid;
    grid-row-gap: 30px;
    font-family: sans-serif;
  }

  .ReportingDashboard__card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    min-height: 420px;

    .ReportingDashboard_card-info,
    .ReportingDashboard_card-info span {
      font-size: 12px;
      color: $gray-700;

      strong {
        font-weight: $font-weight-semi-bold;
        color: $gray-800;
      }

      margin-bottom: 12px;
    }

    .ReportingDashboardWidget_wrapper_side_info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      position: relative;
      min-height: 220px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 10%;
        left: 50%;
        /* Adjust the left position based on your column width */
        border-left: 1px dashed #d2d3d5;
        transform: translateX(-50%);
      }
    }

    .ReportingDashboardWidget_wrapper,
    .ReportingDashboardWidget_wrapper_side_info {
      .ReportingDashboardWidget_col-chart-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 32px 0 32px;

        canvas {
          width: 14.375rem;
        }
      }

      .ReportingDashboardWidget_chart-info,
      .ReportingDashboardWidget_chart-info span {
        font-size: 14px;
        color: $gray-700;
        text-align: center;

        strong {
          font-weight: $font-weight-semi-bold;
          color: $gray-800;
        }
      }

      .ReportingDashboardWidget_col-unprotected-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 32px 0 32px;

        i {
          color: $red-50;
          font-size: 1.714rem;
          margin-bottom: 8px;
        }
      }
    }

    .ReportingDashboardSeatUsage_wrapper {
      display: grid;
      position: relative;
      min-height: 220px;
      justify-items: center;

      .ReportingDashboardSeatUsage_column-wrapper {
        padding: 0 32px 0 32px;

        canvas {
          width: 14.375rem;
        }
      }

      .ReportingDashboardSeatUsage_chart-info {
        font-size: 14px;
        color: $gray-700;
        text-align: center;

        strong {
          font-weight: $font-weight-semi-bold;
          color: $gray-800;
        }
      }
    }
  }
}