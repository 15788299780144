@import "../variables";

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 12px;
    font-family: "Inter";
    color: $white-color;
    font-weight: $font-weight-default;
    // line-height: 2rem;
    background-color: $black-color;
  }
}
