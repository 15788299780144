@import "../variables";

.ProtectionCoverageReport__wrapper {
  display: grid;
  grid-row-gap: 25px;

  .ProtectionCoverageReport__back-button {
    margin-bottom: -2rem;
    color: $blue-50;

    i::before {
      margin: 0;
    }
  }

  .ProtectionCoverageReport__title {
    margin-bottom: 0.8rem;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 0.8rem;
  }

  .ProtectionCoverageReport__info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2.143rem;
  }
}