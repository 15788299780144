@import "../variables";

.Chart__wrapper {
  text-align: center;
  .Chart__value {
    margin-top: -2rem;
  }

  .Chart__title {
    display: flex;
    justify-content: center;
    align-items: center;
    .Chart__title-text {
      font-size: 10px;
    }

    .Chart__title-dot {
      content: "";
      display: inline-block;
      width: 8px; /* Adjust the size of the dot */
      height: 8px; /* Adjust the size of the dot */
      // background-color: $danger-6;
      border-radius: 50%;
      margin-right: 5px; /* Adjust the spacing between the dot and text */
    }
  }
}
