@import "../variables";

.Dashboard__wrapper {
  display: grid;
  grid-row-gap: 25px;

  .Typography__wrapper {
    font-size: 2rem;
    margin-bottom: 0.8rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
    letter-spacing: 0;
    margin-bottom: 0.8rem;
  }

  .Dashboard__content-wrapper {
    display: grid;
    grid-row-gap: 30px;
    font-family: sans-serif;
  }

  .Filter__wrapper {
    display: block;
  }

  .Filter__content-wrapper {
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(9, 14, 18, 0.03);
    border-radius: 0.25rem;
    min-width: 220px;
    padding: 0.5rem 1rem;
    position: relative;
    text-align: left;

    .MuiTypography-root {
      &:first-child {
        color: #636d78;
        // margin-bottom: 0.2rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25;
      }
      color: #272c30;
      font-weight: 600;
      text-transform: none;
    }
  }

  .Dashboard__card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    height: 560px;

    .DashboardCard__content-number {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
    }

    .DashboardCard__content-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }

    .DashboardCard__content-period {
      padding: 4px 0px 10px 0px;
      color: #636d78;
      font-size: 0.85714286rem;
    }

    .DashboardCard__content-chart {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }

    .DashboardCard__content-details {
      padding: 10px 0px 0px 0px;
      font-size: 12px;

      ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        // list-style-type: none;

        li {
          padding: 6px 0px;
          line-height: 20px;
          position: relative;
          display: block;

          span {
            font-weight: 600;
            float: right !important;
          }
        }

        .child {
          padding-left: 1rem !important;
        }
      }
    }

    .DashboardCard__content-image {
      text-align: center !important;
      padding-top: 3rem !important;
      margin-top: 3.5rem !important;
    }

    .DashboardCard__content-image-desc {
      text-align: center !important;
      padding-top: 1.5rem !important;
      font-size: 14px;
      line-height: 25px;
      color: #77838f;
    }
  }
}
