/* ===============================================================================================
	File Name: list-group.scss
	Description: Contain list item, list group related extended SCSS.
	----------------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/

// Inline list style with pipeline seperator

ul.list-inline {
	li {
		display: inline-block;
	}

	&.list-inline-pipe {
		>li+li:before {
			content: ' | ';
			color: $gray-300;
		}
	}
}

/* Inline users list - Use in Widget page */
/*---------------------------------------*/
.users-list {
	li+li {
		margin-left: -.785rem;
	}

	li {
		img {
			border: 2px solid $white;
		}

		.badge {
			border: 2px solid $white;
			border-radius: 50%;
			padding: 0.4rem 0.4rem;
		}
	}
}