.notification-settings-page{
  .notification-settings-card{
    .card-header{
      border-bottom: $primary-1;
    }

    .card-footer {
      position: absolute;
      bottom: 0;
      width: 95%;
    }

    .notification-settings-sidebar {
      .guidebook {
        padding: 1.5rem;
      }

      background-color: #F9F9FB;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30em;

      .list-group {
        width: 100%;

        .list-group-item {
          background-color: transparent;
          border: 0;
        }

        .list-group-item.active {
          background-color: $white;
          color: $primary;
        }
      }
    }

    .notification-settings-card-content{
      padding: 1.25rem;

      a {
        font-size: $font-size-base;

      }

      /* Form Group CSS */
      .form-check {
        padding: 0;

        label {
          font-size: $font-size-base;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: 0;
          opacity: 0.01;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 1.5rem;
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25rem;
          height: 1.25rem;
          background: $primary-1;
          border: 0.06rem solid #E4E9ED;
          box-sizing: border-box;
          border-radius: 0.12rem;
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: "\2713";
          position: absolute;
          top: 0;
          left: 0;
          color: $blue-50;
          text-align: center;
          display: table;
          line-height: 1.15rem;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
        }

        [type="checkbox"]:checked + label:after {
          opacity: 1;
          width: 1.25rem;
          height: 1.25rem;
          border: 0.06rem solid $blue-50;
          box-sizing: border-box;
          background: $blue-20;
          border-radius: 0.12rem;
          line-height: 1.15rem;
        }

        /* Disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #e9e9e9;
        }

        [type="checkbox"]:disabled:checked + label:after {
          color: #777;
        }

        [type="checkbox"]:disabled + label {
          color: #aaa;
        }

        /* Accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
        }
      }

      .form-group{
        margin-bottom: 0rem;

        label {
          font-size: $font-size-base;
          line-height: 1.225rem;
          font-weight: $font-weight-normal;
        }

        label.btn{
          border: 0.06rem solid #ccd6e6;
        }

        label.active {
          border: 0.06rem solid $blue-50;
          background: $blue-20;
        }

        .floating-right-icon{
          position: relative;
          float: right;
          right: 1rem;
          bottom: 1.9rem;
        }
      }

      h3.title{
        font-size: 1.125rem;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        box-shadow: inset 0px -1px 0px #F2F4F6;
      }

      .notification-setting-content{
        display: none;

        &.active{
          display: block;
        }
      }

      .inline_text {
        white-space: normal !important;
      }

      @include media-breakpoint-up(xxl) {
        .inline_text {
          white-space: nowrap !important;
        }
      }
    }
  }

  .page-content {
    margin-left: 15px;
  }
}

#notification-preview-modal {
  max-width: 720px;
  width: 720px;

  .subject {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .subject-content {
    margin-left: 3rem;
    margin-right: 3rem;
    color: #1D2431;
  }
}

.preview-content {
  font-weight: 400;
  color: #42526E
}