.notifications-page {
  /* Search box */
  .table-filters {
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    .filter {
      display: block;
    }

    .input-search {
      border: none;
    }
  }

  /* Form Group CSS */
  .form-check {
    padding: 0.3rem 0;

    label {
      font-size: $font-size-base;
      color: $gray-750;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 1.8rem;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      background: $shadow;
      border: 0.06rem solid #D7DDE4;
      box-sizing: border-box;
      border-radius: 0.12rem;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "\2713";
      position: absolute;
      top: 0;
      left: 0;
      color: $blue-50;
      text-align: center;
      display: table;
      line-height: 1.15rem;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      width: 1.25rem;
      height: 1.25rem;
      border: 0.06rem solid $blue-50;
      box-sizing: border-box;
      background: $blue-20;
      border-radius: 0.12rem;
      line-height: 1.15rem;
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
      color: #777;
    }

    [type="checkbox"]:disabled + label {
      color: #aaa;
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
    }

    .sr-only {
      height: 1.5rem !important;
    }
  }

  .is-invalid {
    border-color: #DD252F;
    padding-right: 2.75rem;
    background-repeat: no-repeat;
    background-position: right calc(0.3125em + 0.375rem) center;
    background-size: calc(0.625em + 0.75rem) calc(0.625em + 0.75rem);
  }

  .invalid-feedback {
    display: block;
  }

  .empty-state {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .item {
    background-color: #F1F8FE;
    color: $gray-750;
    display: block;
    padding: 1rem 1.5rem;
    margin-left: 0.05rem;
    position: relative;
    line-height: 1.5rem;

    .type, .timestamp, .mark-read, .mark-unread {
      font-size: .8rem;
    }

    .type {
      color: $gray-500;
      margin-bottom: .5rem;
    }

    .title {
      color: black;
      line-height: inherit;
      margin-top: .7rem;
    }

    .body {
      font-size: .9rem;
      margin-bottom: .8rem;
    }

    .link {

    }

    .timestamp {
      color: $gray-500;
      margin-right: .8rem;
    }

    .mark-read {
      color: #0068c8;
      cursor: pointer;
    }

    .mark-unread {
      color: #5F6F89;
      cursor: pointer;
    }

    &.read {
      background-color: $white;
    }
  }

  .footer-list {
    background-color: #F1F8FE;

    &.read {
      background-color: $white !important;
    }
  }

  i.line-go-to-link {
    margin-left: 0.5rem;
    font-size: 0.5rem;
    position: absolute;
    bottom: 1.2rem;
  }

  i.line-done-all {
    font-size: 0.7rem;
    position: relative;
    bottom: 0.1rem;
    right: 0.3rem;
  }

  .last-page-footer {
    color: #42526E;
  }

  .sub-action {
    font-size: 0.9rem;
    margin-top: 0.9rem;

    .latest {
      font-weight: 600;
      color: #97A3B8;
    }
    .mark-all, .mark-all-read, .mark-all-unread {
      float: right;
      color: #0068c8;
      cursor: pointer;
    }
  }


  /* Card style */
  .notification-card {
    margin-bottom: 0.875rem;

    &.with-border {
      border: 1px solid #F0F0F0;
    }

    .card-title {
      text-transform: none;
    }

    .card-icon {
      background-color: $blue-50;
      border-radius: 50%;
      color: $white;
      font-size: 1.2rem;
      width: 2rem;
      height: 2rem;
      line-height: 2.1rem;
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.empty {
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 42rem;
        line-height: 2rem;
      }
    }
    .link {
      font-size: 0.9rem;
    }

    .anchor {
      display: block;
      position: relative;
      top: -3.7em;
      visibility: hidden;
    }
  }
}

#notification-datatable {
  margin-top: 2rem;
}