#integration-service-ticket {
  select {
    pointer-events: none;
    opacity: 0;
    display: flex !important;
    position: absolute;
  }

  .ss-main {
    display: flex !important;

    .ss-values {
      overflow: hidden;
    }
  }
}

.menu-list-wrapper {
  .menu-list {
    padding: .625rem 1.25rem .625rem 1.25rem;
    color: $blue-50;
    font-size: $font-size-sm;
    line-height: 1.25rem;
    cursor: pointer;

    &.active {
      background-color: #CCE3F9;
    }

    &:hover:not(.active) {
      background-color: #F2F4F6;
    }
  }
}

.psa-list {
  h2 {
    display: flex;
    align-items: center;

    .badge {
      font-size: .6rem;
      margin-left: .6rem;
    }
  }
}

.integration-mapping-page,
.integration-settings-page {

  .ss-main {
    .ss-values {
      overflow: hidden;
    }
  }

  h1, h3, h4 {
    small {
      font-size: 70%;
    }
  }

  h3, h4 {
    margin-top: .5rem;
    margin-bottom: 0;
  }

  .alert {
    h3, h4 {
      margin-top: 0;
    }
  }

  .card {
    .card-header {
      border-bottom: 1px solid $border-color;
      padding: 1rem 1.5rem;
    }
  }

  #mapping-table {
    .tabulator-row {
      margin-top: 0.5rem;

      > .tabulator-cell:first-child {
        justify-content: center;
      }
    }

    .tabulator-cell {
      &.disabled {
        background-color: $gray-500;
      }
    }

    .solid-alert-check {
      color: $success;
    }

    .solid-alert-warning {
      color: $danger;
    }

    .row-message {
      margin-left: 18rem;
      padding-bottom: 5px;
      margin-top: -10px;

      i {
        margin-right: .3rem;
      }
    }

    .btn {
      i.solid-save {
        color: $blue-50;
      }

      i.line-link-broken {
        color: $danger-5;
      }

      &.disabled {
        i {
          color: $primary-3;
        }
      }
    }
  }

  .content-sidebar {
    .list-group-item {
      border: none;
      border-radius: 0;
      padding: .5rem;
    }
  }

  form {
    label {
      font-weight: $font-weight-bold;
    }
  }
}

.modal-psa {
  .modal-header {
    background-color: $blue-20;
    text-align: center;
    padding: 4rem 2rem;

    img {
      margin-bottom: 1rem;
    }
  }

  .modal-body {
    padding: 2rem 1.5rem;

    h3 {
      margin-bottom: 1.5rem;
    }
  }

  .items {
    margin-top: 1rem;

    .logo-wrap {
      text-align: center;
      padding: 0 .8rem;
    }

    .card {
      margin-bottom: .5rem;

      .row {
        height: 4rem;
      }
    }
  }
}