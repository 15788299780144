@import "../variables";

.ChartC3__wrapper {
  margin-left: -4rem;
  margin-right: -4rem;

  .c3-legend-item text {
    font-size: 10px;
    font-family: Inter;
  }

  .c3-legend-item-tile {
    shape-rendering: auto;
    stroke-linecap: round;
    stroke-dasharray: 0.1 10;
    stroke-width: 8;
    transform: translate(6px, 1px);
  }

  .c3-chart-arc text {
    font-size: 2rem !important;
    font-weight: 600 !important;
    font-family: Inter;
    // fill: none;
  }
}

.ChartC3__wrapper.ChartC3__type-donut {
  .c3-chart-arc text {
    font-size: 0.65rem !important;
    font-weight: 600 !important;
    font-family: Inter;
  }
  .c3-chart-arcs-title {
    font-size: 1rem !important;
    font-family: Inter;

    & .total-value {
      font-size: 1.5rem;
      line-height: 1rem;
    }
  }

  .c3-legend-background {
    display: none;
  }

  .c3-legend-item {
    text {
      font-size: 13px;
      font-family: Inter;
      color: $font-color-default;
      line-height: 1.5;
    }

    .c3-legend-item-tile {
      shape-rendering: auto;
      stroke-linecap: square;
      stroke-dasharray: 0.1 10;
      stroke-width: 8;
      transform: translate(6px, 1px);
    }
  }

  
}

.ChartC3__wrapper.ChartC3__type-stackedBar,
.ChartC3__wrapper.ChartC3__type-multiple {
  margin-left: 0rem;
  margin-right: 0rem;
  svg {
    overflow: hidden;
    width: 100%;
    display: block;
  }
}

.ChartC3__type-multiple {
  .c3-legend-item-tile {
    // stroke-linecap: square;
  }
}