@import "../variables";

.PartnerHeaderSection__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.PartnerHeaderSection__header-partner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button i.solid-add {
    &::before {
      font-size: 1.429rem;
      margin: 0rem;
    }
  }
}