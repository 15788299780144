@import "../variables";

.ErrorPage__wrapper {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  font-size: 9rem;
  font-weight: 800;
}
