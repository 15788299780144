@import "../variables";

.NppDatePicker__wrapper {
  .container__months.columns-2 {
    box-shadow: none;
  }

  .container__footer {
    box-shadow: none;
  }
}

.MuiButtonBase-root.NppDatePicker__button {
  padding: 0.429rem 1.143rem;
  height: 2.571rem;

  i {
    color: $blue-50;
    font-size: 0.85rem;
  }
}