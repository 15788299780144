.smtp-settings-page{
  .smtp-settings-card{
    .card-header{
      border-bottom: $primary-1;
    }

    .smtp-settings-card-content{
      padding: 1.25rem;
      
      /* Form Group CSS */
      .form-group{
        label{
          font-size: $font-size-sm;
          line-height: 1.225rem;
          font-weight: $font-weight-normal;
        }

        .floating-right-icon{
          position: relative;
          float: right;
          right: 1rem;
          bottom: 1.9rem;          
        }

        .password-open{
          cursor: pointer;
          color: $gray-500;

          &:hover{
            color: $black;
          }
        }

        .invalid-feedback{
          display: block;
        }
      }

      h3.title{
        font-size: 1.125rem;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        box-shadow: inset 0px -1px 0px #F2F4F6;
      }

      .smtp-setting-content{
        display: none;

        &.active{
          display: block;
        }
      }

      /* DEFAULT SMTP PART */
      .default-smtp{
        .default-text{
          font-size: $font-size-sm;
          line-height: 1.225rem;
          font-weight: $font-weight-normal;
          margin: 1.5rem 0 1.5rem;
        }
      }

      /* CUSTOM MANUAL PART */
      .custom-manual-smtp{
        .inputs{
          .custom-radio{
            input[type='radio']{
              position: relative;
              top: 2px;
            }

            label{
              padding-left: 5px;
              cursor: pointer;
              line-height: 1.25rem;
            }
          }
        }
      }

      /* CUSTOM Microsoft PART */
      .custom-microsoft-smtp{
        .sign-in-button{
          a{
            font-size: $font-size-sm;

            img{
              width: 1.188rem;
              height: 1.188rem;
              margin-right: 0.5rem;
            }
          }
        }

        .signed-in-text{
          font-size: $font-size-sm;
          font-weight: $font-weight-normal;
          margin-bottom: 1.5rem;
          padding-top: 1.5rem;
          border-top: 1px solid #F2F4F6;
        }
      }

      /* CUSTOM SMTP Sidebar */
      .smtp-sidebar{
        .send-test-email{
          margin-top: 0.65rem;
          display: none;

          &.active{
            display: block;
          }

          .input-group{
            input[type='text']{
              margin-right: 0.5rem;
            }
          }

          p {
            color: $gray-750;
            line-height: 1.825rem;
          }
        }
      }
    }  
  }
}