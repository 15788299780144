@import '../variables';

.NppAlert__wrapper {
  &.MuiAlert-root {
    padding: 0.857rem 1.143rem 0.857rem 1.143rem;
  }

  &.MuiAlert-standardError {
    background: $bg-danger;
    border: 0.1rem solid $border-danger;
    border-radius: 0.4rem;
  }

  &.MuiAlert-standardSuccess {
    background: $green-20;
    border: 0.1rem solid $green-30;
    border-radius: 0.4rem;

    .MuiAlert-icon i {
      color: $green-50;
    }
  }

  &.MuiAlert-standardInfo {
    background: $bg-info;
    border: 0.1rem solid $border-info-3;
    border-radius: 0.4rem;
  }

  p.MuiTypography-root,
  p {
    font-family: $font-family;
    font-size: $font-size-small;
    font-weight: $font-weight-default;
    color: $mute-color;
    margin: 0;

    b {
      font-weight: $font-weight-semi-bold;
    }
  }

  .MuiAlert-icon {
    padding: 0rem;

    i::before {
      margin: 0rem;
    }

  }

  .MuiAlertTitle-root {
    font-family: $font-family;
    font-size: $font-size-default;
    font-weight: $font-weight-semi-bold;
    color: $black-color-3;
  }

  .MuiAlert-action {
    margin-top: -0.56rem;

    svg {
      font-size: $font-size-default;
    }

    button {
      outline: none;
    }
  }

  .MuiAlert-message {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.NppAlert__wrapper.NppAlert__without-heading {
  .MuiAlert-icon {
    padding: 0rem;
    align-items: center;

    i.NppAlert-icon {
      padding-top: 0.1rem;

      &::before {
        margin: 0rem;
      }
    }
  }

  p.MuiTypography-root,
  p {
    font-size: $font-size-default;
  }

  p.MuiTypography-root a,
  p a {
    color: $black-color;
    text-decoration: underline;
  }

  .MuiAlert-action {
    padding: 0.8rem 0 0.8rem 8rem;
  }
}

.NppAlert__wrapper.NppAlert__without-margin {
  margin-bottom: 0;
}