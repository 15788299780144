@import '../variables';

.NppModal__default {
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-default;
  font-size: $font-size-small;
  line-height: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  outline: 0;

  .NppModal__wrapper {
    width: 46rem;
    background-color: $bg-white-color;
    border: none;
    box-shadow: $shadow-depth-100;
    border-radius: 0.4rem;
    margin: 7rem auto;
    color: $black-color-3;
    overflow: hidden;
    outline: 0;
  }

  .NppModal__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 1.714rem 1.714rem 1.357rem;
  }

  .NppModal__header .MuiTypography-root.MuiTypography-h5 {
    font-weight: $font-weight-bold;
    line-height: 3.2rem;
    color: $black-color;
  }

  .NppModal__close-icon {
    display: flex;
    align-items: center;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .NppModal__body {
    padding: 0rem 1.714rem;
    overflow-x: hidden;
    max-height: 86rem;
  }

  .NppModal__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1.714rem 1.714rem;
    gap: 1rem;
  }

  .NppModal__button-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .NppModal__button-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .NppModal__button-no-padding button {
    padding: 0;
    height: 0;
    min-width: 0;
  }
}
