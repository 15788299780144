// =========================================================================================
//   File Name: avatar.scss
//   Description: Avatar images.
//   ----------------------------------------------------------------------------------------
//   Item Name: Stack - Responsive Admin Theme
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: Avatar
// -------------------

.avatar {
  position: relative;
  white-space: nowrap;
  border-radius: 50%;
  vertical-align: middle;
  height: $avatar-size;
  width: $avatar-size;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .badge-up{
    position: absolute;
    right: -8px;
    top: -8px;
  }

  // Status Modifier
  // -------------------
  &[class*=avatar-] i {
    border-radius: 50%;
    width: $avatar-status-size;
    height: $avatar-status-size;
    position: absolute;
    right: -2px;
    bottom: 0;
    border: 1px solid $white;
  }

  &.avatar-away i {
    background-color: $avatar-status-away-color;
  }

  &.avatar-busy i {
    background-color: $avatar-status-busy-color;
  }

  &.avatar-offline i {
    background-color: $avatar-status-offline-color;
  }

  &.avatar-online i {
    background-color: $avatar-status-online-color;
  }

  // Avatar Images
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0 none;
    border-radius: 50%;
  }

  // Size Modifier
  // -------------------

  &.avatar-xl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
    font-size: 1.5rem;
    &[class*=avatar-] i {
      width: $avatar-status-size-xl;
      height: $avatar-status-size-xl;
    }
  }

  &.avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    font-size: 1.2rem;
    &[class*=avatar-] i {
      width: $avatar-status-size-lg;
      height: $avatar-status-size-lg;
    }
  }

  &.avatar-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
    font-size: 1rem;
    &[class*=avatar-] i {
      width: $avatar-status-size-sm;
      height: $avatar-status-size-sm;
    }
  }
}