@use "sass:math";

form{
    label{
        color: darken($content-bg, 70%);
    }
    .form-group{
        margin-bottom: 1.5rem;
    }

    .form-control{
        border: 1px solid darken($content-bg, 12%);
        color: darken($content-bg, 60%);

        &::-webkit-input-placeholder {
           color: darken($content-bg, 20%);
        }

        &:-moz-placeholder { /* Firefox 18- */
           color: darken($content-bg, 20%);
        }

        &::-moz-placeholder {  /* Firefox 19+ */
           color: darken($content-bg, 20%);
        }

        &:-ms-input-placeholder {
           color: darken($content-bg, 20%);
        }
    }
    .form-section{
        color: $gray-200;
        line-height: 3rem;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray-200;

        i{
            font-size: 20px;
            margin-left: 5px;
            margin-right: 10px;
        }
    }

    /* .form-body{
        overflow: hidden;
    } */

    .form-actions{
        border-top: 1px solid darken($content-bg, 10%);
        padding: 20px 0;
        margin-top: 20px;

        &.filled{
            background-color: $content-bg;
        }
        &.center{
            text-align: center;
        }
        &.right{
            text-align: right;
        }

        &.top{
            border-top: 0;
            border-bottom: 1px solid darken($content-bg, 10%);
            margin-top: 0;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            .buttons-group{
                float: left !important;
                margin-bottom: 10px;
            }
        }
    }

    &.form-horizontal{
        .form-group{
            @include media-breakpoint-up(sm) {
                .label-control{
                    text-align: right;
                }
            }
        }
    }

    &.row-separator{
        .form-group{
            margin: 0;
            border-bottom: 1px solid darken($content-bg, 3%);
            .label-control{
                padding-top: 1.5rem;
            }
            > div{
                padding: 1.2rem;
                padding-right: 0;
            }
            &.last{
                border-bottom: 0;
            }
        }
    }

    &.form-bordered{
        .form-group{
            margin: 0;
            border-bottom: 1px solid darken($content-bg, 3%);
            .label-control{
                padding-top: 1.5rem;
            }
            > div{
                padding: 1.2rem;
                padding-right: 0;
                border-left: 1px solid darken($content-bg, 3%);
            }
            &.last{
                border-bottom: 0;
            }
        }
    }

    &.striped-rows{
        .form-group{
            margin: 0;

            .label-control{
                padding-top: 1.5rem;
            }

            > div{
                padding: 1.2rem;
                padding-right: 0;
            }

            &:nth-child(even){
                background-color: $content-bg;
            }
        }
    }

    &.striped-labels{
        .form-group{
            margin: 0;

            .label-control{
                padding-top: 1.5rem;
            }

            > div{
                padding: 1.2rem;
                padding-right: 0;
                background-color: white;
            }

            &:nth-child(even){
                background-color: $content-bg;
            }
        }
    }

    .custom-control-indicator{
        width: 1.1rem;
        height: 1.075rem;
        line-height: 1.05rem;
        top: 0.2rem;
        left: 0;
        + .custom-control-description{
            margin-left: 1rem;
        }
    }
}

select.form-control {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
    -moz-appearance: none; // For firefox
    -webkit-appearance: none; // for chorme
}

// For customs-select remove double arrow
.custom-select{
    -moz-appearance: none; // For firefox
    -webkit-appearance: none; // for chorme
}

// For custom switch 
.custom-switch {  
    .custom-control-label {
      &::after {
        top: add(math.div($font-size-base * $line-height-base - $custom-control-indicator-size, 1.8), $custom-control-indicator-border-width * 2);
      }
    }
}

// Custom checkbox
.input-group-addon {
    background-color: #ECEFF1;
    border-color: #BABFC7;
}

//Custom Checkbox label
.custom-control-label{
    &::before,
    &::after{
        width: 1.1rem;
        height: 1.1rem;
    }
}

//form-tags-input
.tagging-js-tags, .tagging-methods{
    .form-control{
        height: auto;
    }
}