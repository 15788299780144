// The dropdown menu
.dropdown {
  .dropdown-menu {
    // display: block; // If you open this will create issue in tabs and pills pages of components
    // min-width:160px;
    border: 1px solid #e6e6e6;
    //Add the dropdown open and close animation
    // opacity:0.3;
    border-radius: 0 0 $border-radius $border-radius;
    transform-origin: top;
    animation-fill-mode: forwards;
    transform: scale(1, 0);
    transition: all 0.2s linear;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    // display: block;

    .dropdown-header {
      padding: 10px 20px;
      margin-top: 10px;
      text-transform: uppercase;
      color: $gray-300;
    }
    .dropdown-item {
      padding: 10px 40px 10px 20px;
      width: auto;
      cursor: pointer;
      &:active {
        background-color: #f2f4f7 !important;
        color: $dropdown-color;
      }
    }
  }
  i {
    margin-right: 0.5rem;
  }
}
.nav-item {
  i {
    margin-right: 0.5rem;
  }
}

.show {
  > .dropdown-menu {
    transform: scale(1, 1);
    opacity: 1;
    display: block;
  }
}

.dropdown-toggle {
  // changed icon caret

  &::after {
    font-family: "FontAwesome";
    content: "\f107" !important;
    border: none !important;
    position: relative;
    top: 1px;
    right: 0px;
    padding: 0 2px 0 6px;
    margin: 0 0.3em 0 0;
    vertical-align: 0;
  }
  // hide default arrow to show custom icon DD
  &.nav-hide-arrow {
    &::after {
      display: none;
    }
  }

  // Prevent the focus on the dropdown toggle when closing dropdowns
  &:focus {
    outline: 0;
  }
}
// Horizontal layout navigation menu dropdown arrow
.navbar-horizontal {
  .dropdown-submenu {
    .dropdown-toggle::after {
      top: -9px !important;
      margin-top: 1.25rem;
      right: 10px;
    }
  }
}
// Button with dropdown arrow

// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown menu with the parent.
// .dropdown-menu-right {
//   left: auto !important;
//   right: 0;
// }

// Multileval Dropdown Menu
// -------------------------
.dropdown-menu {
  box-shadow: none;
  .dropdown-submenu {
    position: relative;
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
    > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      margin-left: -1px;
      &.open-left {
        left: auto;
        right: 100%;
      }
    }
    > a:after,
    > button:after {
      font-family: "FontAwesome";
      content: "\f105" !important;
      right: 5px;
      // top: -15px;
      font-size: 1.2rem;
      float: right;
      border: none;
      position: absolute;
    }
    :hover {
      > a:after,
      > button:after {
        border-left-color: #555;
      }
    }
    .pull-left {
      float: none;
      > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
      }
    }
  }
}

// Highlight header
.dropdown-header {
  text-transform: uppercase;
  &.highlight {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

// Color theme active & hover
.btn-secondary ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: darken(#ffffff, 20%);
  }
}
.btn-primary ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: $primary;
  }
}
.btn-success ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: $success;
  }
}
.btn-danger ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: $danger;
  }
}
.btn-info ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: $info;
  }
}
.btn-warning ~ .dropdown-menu {
  .dropdown-item.active {
    background-color: $warning;
  }
}

// Arrow
.dropdown-menu {
  &.arrow {
    margin-top: 12px;
    &:after,
    &:before {
      position: absolute;
      left: 10px;
      display: inline-block;
      width: 0;
      height: 0;
      content: "";
      border: 7px solid transparent;
      border-top-width: 0;
    }
    &:before {
      top: -7px;
      border-bottom-color: lighten(#000000, 70%);
    }
    &:after {
      top: -6px;
      border-bottom-color: #fff;
    }
  }
  .arrow-left {
    margin-left: 7px !important;
    &:after,
    &:before {
      position: absolute;
      top: 10px;
      display: inline-block;
      width: 0;
      height: 0;
      content: "";
      border: 7px solid transparent;
      border-left-width: 0;
    }
    &:before {
      left: -8px;
      border-right-color: lighten(#000000, 70%);
    }
    &:after {
      left: -7px;
      border-right-color: #fff;
    }
  }
  .arrow-right {
    margin-right: 7px !important;
    &:after,
    &:before {
      position: absolute;
      top: 10px;
      display: inline-block;
      width: 0;
      height: 0;
      content: "";
      border: 7px solid transparent;
      border-right-width: 0;
    }
    &:before {
      right: -7px;
      border-left-color: lighten(#000000, 70%);
    }
    &:after {
      right: -6px;
      border-left-color: #fff;
    }
  }
}

.dropup {
  .dropdown-toggle {
    // changed icon dropup caret
    &::after {
      content: "\f106" !important;
    }
  }
  .dropdown-menu {
    top: auto;
    // bottom: 100%;
    &.arrow {
      margin-bottom: 12px;
      &:after,
      &:before {
        top: auto;
        border-top-width: 7px;
        border-bottom-width: 0;
      }
      &:before {
        bottom: -8px;
        border-top-color: lighten(#000000, 70%);
      }
      &:after {
        bottom: -6px;
        border-top-color: #fff;
      }
    }
  }
  .dropdown-submenu {
    .dropdown-menu {
      bottom: auto;
      top: 0;
    }
  }
}

// Sizes

.dropdown-menu-xl a,
.dropdown-menu-xl button {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 1rem;
  line-height: 1.43;
}
.dropdown-menu-lg a,
.dropdown-menu-lg button {
  padding-top: 6px;
  padding-bottom: 6px;
}
.dropdown-menu-sm a,
.dropdown-menu-sm button {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.86rem;
  line-height: 1.67;
}

.dropdown-item {
  &.buttons-columnVisibility.active {
    color: $black;
  }
}