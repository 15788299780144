.partner-user-page, .modal-dialog{
  .partner-user-table {
    th, td {
      white-space: normal !important;
      vertical-align: middle !important;
      text-align: center;
    }

    th.account {
      width: 20% !important;
    }

    th.tfa {
      width: 10% !important;
    }

    th.signin {
      width: 15% !important;
    }

    .table th, .table td {
      padding: 0.5rem 1.5em !important;
    }

    .active-all {
      vertical-align: text-top;
      padding-left: 0.25rem;
    }
  }

  /* Form Group CSS */
  .form-check {
    padding: 0;

    label {
      font-size: $font-size-base;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 1.5rem;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      background: $primary-1;
      border: 0.06rem solid #E4E9ED;
      box-sizing: border-box;
      border-radius: 0.12rem;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "\2713";
      position: absolute;
      top: 0;
      left: 0;
      color: $blue-50;
      text-align: center;
      display: table;
      line-height: 1.15rem;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      width: 1.25rem;
      height: 1.25rem;
      border: 0.06rem solid $blue-50;
      box-sizing: border-box;
      background: $blue-20;
      border-radius: 0.12rem;
      line-height: 1.15rem;
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
      color: #777;
    }

    [type="checkbox"]:disabled + label {
      color: #aaa;
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
    }

    .sr-only {
      height: 1.5rem !important;
    }
  }

  .is-invalid {
    border-color: #DD252F;
    padding-right: 2.75rem;
    background-repeat: no-repeat;
    background-position: right calc(0.3125em + 0.375rem) center;
    background-size: calc(0.625em + 0.75rem) calc(0.625em + 0.75rem);
  }

  .invalid-feedback {
    display: block;
  }
  .notification-area {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 500px;
  }

}