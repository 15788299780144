#csv-modal-link i {
  color: white;
  font-weight: 500;
}
.sub-title {
  color: $primary-7;
  line-height: 1.5rem;
  font-weight: 400;
}
.csv-dialog {
    padding: 1.5rem;
    justify-content: center;

    .modal-content {
      padding: 0.5rem !important;
      .modal-header {
        border-bottom: none;
        padding-bottom: 0px;
      }
      .modal-body {
        .csv-criteria{
          .criteria {
            border-bottom: 1px solid #F2F4F6;
            padding: 12px 0;
            color: $gray-950;
            .criteria-value {
              font-weight: 400;
              width: 55%;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $gray-750
            }
          }
        }
        .continue {
          font-weight: 400;
          padding: 12px 0;
          color: $primary-7;
          .message-box {
            white-space: normal;
            line-height: 1.5;
            text-align: left;
            padding: 10px;
            color: $primary-7;
            font-size: 14px;
            border: solid 1px $blue-30;
          }
        }
      }
      .modal-footer {
        border-top: none;
        .btn-primary-disabled {
          background-color: #CCE3F9;
          border: none;
          color: #99C8F3;
        }
      }
    }
  }