@import '../variables';

.NppSimpleTable__wrapper {
  .NppSimpleTable__header p {
    font-weight: $font-weight-semi-bold;
  }

  .NppSimpleTable__cell-xs {
    width: 6rem;
  }

  thead {
    border-bottom: 2px solid $border-color;
  }

  table thead tr th,
  table tbody tr td {
    padding: 1.2rem;
  }

}


// data table override
// styling
.NppTable__wrapper table *,
.NppTable__wrapper table .MuiTableCell-root {
  font-size: $font-size-default;
  font-family: 'Inter';
}

.NppTable__wrapper table {
  .MuiTableRow-head {
    border-top: $thin-border;

    .MuiTableSortLabel-icon {
      margin-left: 1.4rem;
      font-size: 1.6rem;
    }
  }

  .MuiTableRow-head .MuiTableCell-head {
    border-bottom: 2px solid $border-color;

    // &:last-child .NppTable__head-label {
    //   border-left: 0;
    // }
  }

  thead.MuiTableHead-root th {
    border-bottom: $thin-border;
    padding: 0.857rem 0rem 0.857rem;
    // padding: 0rem;
  }

  .NppTable__head-label {
    border-left: $thin-border;
    display: inline-block;
    padding: 0.283rem 0.857rem 0.283rem;

    .MuiTableSortLabel-root i {
      color: $gray-500;
    }

    i.NppTooltip__wrapper {
      // vertical-align: middle;
      color: $blue-50 !important;
      font-size: 1rem;
      margin-left: 0.143rem;

      &::before {
        margin: 0;
      }
    }

  }

  .NppTableCell__icon svg {
    font-size: $font-size-extra-large;
  }

  .MuiTableCell-alignRight .NppTableCell__icon {
    display: flex;
    justify-content: flex-end;
  }

  .NppTable__head-label.NppTable__head-label-no-divider {
    border-left: 0;
  }

  .NppTable__head-label span.MuiTypography-root {
    // display: inline-flex;
    align-items: center;
    white-space: normal;
  }

  th.MuiTableCell-root,
  th.MuiTableCell-root * {
    font-weight: $font-weight-semi-bold;
  }

  tbody.MuiTableBody-root th,
  tbody.MuiTableBody-root td {
    border-bottom: $thin-border;
    padding: 0.857rem;
  }

  thead.MuiTableBody-root th.NppTable__cell-with-thin-pl,
  thead.MuiTableBody-root td.NppTable__cell-with-thin-pl,
  tbody.MuiTableBody-root th.NppTable__cell-with-thin-pl,
  tbody.MuiTableBody-root td.NppTable__cell-with-thin-pl,
  .NppTable__cell-with-thin-pl .NppTable__head-label {
    padding-left: .3rem;
  }

  thead.MuiTableBody-root th.NppTable__cell-no-padding,
  thead.MuiTableBody-root td.NppTable__cell-no-padding,
  tbody.MuiTableBody-root th.NppTable__cell-no-padding,
  tbody.MuiTableBody-root td.NppTable__cell-no-padding,
  .NppTable__cell-no-padding .NppTable__head-label {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  thead.MuiTableBody-root th.NppTable__cell-wrap,
  thead.MuiTableBody-root td.NppTable__cell-wrap,
  tbody.MuiTableBody-root th.NppTable__cell-wrap,
  tbody.MuiTableBody-root td.NppTable__cell-wrap,
  .NppTable__cell-wrap .NppTable__head-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  thead.MuiTableBody-root th.NppTable__cell-size-icon:first-child,
  thead.MuiTableBody-root td.NppTable__cell-size-icon:first-child,
  tbody.MuiTableBody-root th.NppTable__cell-size-icon:first-child,
  tbody.MuiTableBody-root td.NppTable__cell-size-icon:first-child,
  .NppTable__cell-size-icon:first-child .NppTable__head-label {
    padding-left: 2.5rem;
  }

  thead.MuiTableBody-root th.NppTable__cell-wrap,
  thead.MuiTableBody-root td.NppTable__cell-wrap,
  tbody.MuiTableBody-root th.NppTable__cell-wrap,
  tbody.MuiTableBody-root td.NppTable__cell-wrap,
  .NppTable__cell-wrap .NppTable__head-label {
    white-space: pre-wrap;
  }

  .NppTable__cell-size-icon {
    width: 6rem;
  }

  .NppTable__cell-size-xxs {
    width: 3%;
    max-width: 3.6rem;
  }

  .NppTable__cell-size-xs {
    width: 5%;
    max-width: 4.8rem;
  }

  .NppTable__cell-size-sm {
    width: 15%;
    max-width: 15rem;
  }

  .NppTable__cell-size-md {
    width: 33%;
    max-width: 36rem;
  }

  .NppTable__cell-size-lg {
    width: 50%;
    max-width: 44rem;
  }

  .NppTable__icon {
    color: $primary-color;
    font-size: $font-size-extra-large;
    vertical-align: bottom;
  }

  tbody.MuiTableBody-root .NppTableCell__height-size-small td {
    padding: 0.95rem;
  }

  .NppTableRow__with-icon-pull-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      display: flex;
    }

    a svg {
      font-size: $font-size-extra-large;
    }
  }

  .NppTableRow__linking {

    td,
    th {
      cursor: pointer;
    }
  }

  .NppTable__cell-with-color,
  .NppTableRow__active,
  .NppTable__cell-with-color:hover,
  .NppTableRow__active:hover {
    background-color: $bg-gray-color-1;
  }

  .NppTableRow__active-link td,
  .NppTableRow__active-link th,
  .NppTableRow__active-link td svg,
  .NppTableRow__active-link th svg {
    color: $active-color;
  }

  .NppTableRow__selected {
    background-color: $bg-primary;
  }

  mark.NppTypography__mark-label {
    font-weight: $font-weight-extra-bold;
    color: $black-color;
  }
}

.NppTable__wrapper-full-border table {
  .NppTable__head-label {
    border: 0;
    height: 3rem;
    padding: 0;
  }

  thead tr th,
  tbody tr td,
  tbody tr th,
  tbody.MuiTableBody-root th,
  tbody.MuiTableBody-root td,
  tbody.MuiTableBody-root .NppTableCell__height-size-small td,
  tbody.MuiTableBody-root .NppTableCell__height-size-small th {
    border-left: $thin-border;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.NppTable__wrapper .NppTable__dropdown-checkbox {

  padding-left: 1.1rem;

  .NppTable__dropdown-button-primary svg {
    color: $blue-color;
  }

  .NppTable__dropdown-button-inherit svg {
    color: $mute-color-2;
  }

  svg {
    font-size: $font-size-large;
  }
}