.alert{
	position: relative;
	.alert-link{
		&:hover{
			text-decoration: underline;
		}
	}
	&.alert-icon-left {
		border-left-width: 4rem;
	}
	&.alert-icon-right {
		border-right-width: 4rem;
	}
	&.alert-icon-left[class*=bg-]{
		border-left-color:rgba(0,0,0,.15)!important
	}
	&.alert-icon-right[class*=bg-]{
		border-right-color:rgba(0,0,0,.15)!important
	}
	&[class*=alert-icon-]:after {
		content: '\f006';
		font-family: FontAwesome;
		color: #fff;
		width: 4rem;
		left: -4rem;
		text-align: center;
		position: absolute;
		top: 50%;
		margin-top: -8px;
		font-size: 1.25rem;
		font-weight: 400;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.close{
		font-size: 2rem;
		line-height: 1rem;
	}
	&[class*=bg-]{
		color: #fff;
		.alert-link{
			color: #fff;
		}
		.close{
			color: #fff;
			opacity: 1;
			text-shadow: none;
		}
	}
	&[class*=alert-icon-].alert-primary:after,
	&[class*=alert-icon-][class*=alert-primary]:after,
	&[class*=alert-icon-][class*=bg-primary]:after{
		content:'\f08a';
	}
	&[class*=alert-icon-].alert-danger:after,
	&[class*=alert-icon-][class*=alert-danger]:after,
	&[class*=alert-icon-][class*=bg-danger]:after{
		content:'\f088';
	}
	&[class*=alert-icon-].alert-success:after,
	&[class*=alert-icon-][class*=alert-success]:after,
	&[class*=alert-icon-][class*=bg-success]:after{
		content:'\f087';
	}
	&[class*=alert-icon-].alert-warning:after,
	&[class*=alert-icon-][class*=alert-warning]:after,
	&[class*=alert-icon-][class*=bg-warning]:after{
		content:'\f071';
	}
	&[class*=alert-icon-].alert-info:after,
	&[class*=alert-icon-][class*=alert-info]:after,
	&[class*=alert-icon-][class*=bg-info]:after{
		content:'\f129';
	}
	&.alert-icon-right:after{
		left:auto;
		right:-4rem;
	}
}