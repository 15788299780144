.new-plan-page, .edit-plan-page {

    .form-control:disabled, .form-control[readonly] {
        background-color: #f5f5f5;
        opacity: 1;
    }

    .choices {
        &__inner {
            display: block;
            width: 100%;
            background-color: #fff;
            border: 1px solid #ccd6e6;
            border-radius: .25rem;
            min-height: 43px;
            overflow: hidden;
            color: #3f587e;
            height: calc(2.75rem);
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25;
            background-clip: padding-box;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }

        &__list--dropdown {
            color: #3f587e;
            font-size: 1rem;
            font-weight: 400;
            display: none;
            will-change: display;

            &.is-active {
              display: block;
            }
        }

        &.is-disabled {
            background-color: $input-disabled-bg;

            .choices__inner {
                background-color: #f5f5f5;
            }
        }
    }

    .selling-price {
      .choices {
        &__inner {
          border-radius: .25rem 0 0 .25rem !important;
          border-right: 0px;
        }
      }

      input {
        border-radius: 0 .25rem .25rem 0 !important;
      }
    }

    .form-control-position {
        position: absolute;
        top: 0px;

        .fa {
          line-height: calc(2.75rem + 2px);
          vertical-align: middle;
        }
    }
    
    /* Form Group CSS */
    .form-group {
        label.wrap {
            width:200px;
            overflow: hidden; 
            height: 50px;    
            position: relative;
            display: block;
            border:2px solid blue;
        }
        
        select.currency-dropdown{       
            height: 50px;
            padding: 10px;
            border: 0;
            font-size: 15px;       
            width: 200px;
           -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        
        label.wrap:after {
            content:"\f13a ";
            font-family: FontAwesome;
            color: #000;
            position: absolute; 
            right: 0; 
            top: 18px;
            z-index: 1;
            width: 10%;
            height: 100%;  
            pointer-events: none;    
        }
    }

    .input-group {
        > .select-input {
            flex-grow: 2;
            border-right: 0;
            
            .choices__inner {
              border-radius: .25rem 0 0 .25rem;
              border-right: 0;
            }
        }
        :nth-child(2) {
            flex-grow: 6;
            input {
                height: 43px;
                border-radius: 0 .25rem .25rem 0;
                
            }
        }

        select {
            height: auto;
        }

        .danger {
          &-input-group-text-border {
            border-top: 1px solid $red-50;
            border-right: 1px solid $red-50;
            border-bottom: 1px solid $red-50;
            border-left: 0;
            border-radius: 0 .25rem .25rem 0;
            height: 45px !important;

            &.is-invalid:focus {
              box-shadow: none !important;
            }
          }

          &-input-group-select-border {
            border-top: 1px solid $red-50;
            border-left: 1px solid $red-50;
            border-bottom: 1px solid $red-50;
            border-right: 0;
            border-radius: .25rem 0 0 .25rem;
            height: 45px;

            .choices__inner {
              border: 0px;
              border-right: 1px solid #ccd6e6;
              min-height: 35px;
              max-height: 35px;
              margin: 4px 0 4px 0;
              padding-top: 4px;
              border-radius: 0;
            }
          }

          &-border {
              border: 1px solid $red-50;
          }

          input.form-control {
            .is-invalid:focus {
              box-shadow: none;
            }
          }
        }

        .invalid-feedback {
          margin-top: -1.25em;
          margin-bottom: 1em;
        }
    }

    .choose-reseller-section {
        .scroll-wrapper {
            overflow-x: scroll;
            max-height: 200px;
        }
    }

    .invalid-feedback {
        display: block;
    }

    .form-check {
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: 0;
          opacity: 0.01;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 1.5rem;
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25rem;
          height: 1.25rem;
          background: $primary-1;
          border: 0.06rem solid #E4E9ED;
          box-sizing: border-box;
          border-radius: 0.12rem;
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: "\2713";
          position: absolute;
          top: 0;
          left: 0;
          color: $blue-50;
          text-align: center;
          display: table;
          line-height: 1.15rem;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
        }

        [type="checkbox"]:checked + label:after {
          opacity: 1;
          width: 1.25rem;
          height: 1.25rem;
          border: 0.06rem solid $blue-50;
          box-sizing: border-box;
          background: $blue-20;
          border-radius: 0.12rem;
          line-height: 1.15rem;
        }

        /* Disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #e9e9e9;
        }

        [type="checkbox"]:disabled:checked + label:after {
          color: #777;
        }

        [type="checkbox"]:disabled + label {
          color: #aaa;
        }

        /* Accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
        }
    }


    .is-invalid {
      border-color: #DD252F;
  }

  .select2-container--default .select2-selection--single
  {
    border-radius: .25rem;
    min-height: 44px;
    height: calc(2.75rem + 2px);
    padding: 0.5rem 0.7rem;
    font-size: 1rem;
  }
}