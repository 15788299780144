.support-tickets-page {
  .product-icon {
    color: $blue-50;
  }
}

.create-support-ticket, .ticket-actions {
  .topic_info .alert {
    background: #F5F6FA !important;
    border-color: #D7DDE4!important;
    strong.activity {
      margin-right: 3px
    }
    strong.account {
      margin-left: 3px
    }
  }

  .attach-files {
    color: $blue-50;
    cursor: pointer;
  }

  .hide {
     visibility: hidden;
  }

  /* FilePond CSS */
  .filepond--root {
    margin-left: -1em !important;
    .filepond--drop-label {
      height: 0px;
      min-height: 0px;
    }
  }
  .filepond--drop-label {
    justify-content: flex-start;
    visibility: hidden; 
    pointer-events: none;
    will-change: auto;

    label {
      color: $blue-50;
    }
    .filepond--label-action {
      text-decoration: none
    }
  }

  .filepond--panel-root {
    background-color: transparent !important
  }

  .filepond--item {
    width: calc(25% - 0.5em);
    padding: 0.3em 0 0.3em 0.5em;
    height: 3.6em !important;
    background: #FBFBFB;
  }

  .filepond--panel.filepond--item-panel {
    border: 0.5px solid #E6E8EA !important;
    border-radius: 4px;
  }

  .filepond--file {
    color: #42526E;
  }

  .filepond--item-panel {
    background: #FBFBFB;
    box-shadow: none !important;
  }

  .filepond--file-action-button {
    color: #42526E;
    border-radius: 0px !important;
    background-color: transparent;
  }

  .filepond--list-scroller {
    visibility: visible;
  }

  .filepond--panel-bottom {
    visibility: hidden;
    //border: inherit;
    //border-radius: inherit;
  }
  .filepond--file-info {
    padding-right: 2em;
  }
  .filepond--progress-indicator path {
    stroke: $blue-50 !important;
  }
  .filepond--file-status-sub {
    display: none;
  }
  .filepond--file-status-main {
    margin-top: 0.5em;
  }
}

.support-tickets-detail-page {
  .message {
    .avatar {
      color: $body-color;
    }
  }
}