@import "variables";

// *,
// *:after,
// *:before {
//   box-sizing: border-box;
// }

// .MuiTypography-root.MuiLink-root {
//   color: $bg-blue-color;
// }

// html,
// body,
// body #root {
//   font-family: $font-family  !important;
//   font-weight: $font-weight-default;
//   line-height: $font-line-height-default;
//   color: $font-color-default;
//   font-size: $font-size-default;
// }

// html,
// body,
// a,
// p,
// body #root {
//   text-decoration: none;
//   letter-spacing: $font-letter-spacing-default;
// }

// body,
// p,
// body #root {
//   font-size: $font-size-default;
// }

// html {
//   box-sizing: border-box;
//   font-size: 62.5%;
// }

body {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: $font-weight-bold;
}

.bold {
  font-weight: bold;
}

.MuiTypography-h5,
.MuiTypography-h4,
.MuiTypography-h3,
.MuiTypography-h2,
.MuiTypography-h1 {
  font-weight: $font-weight-bold !important;
  color: $black-color;
}

@each $key, $val in $font-size-heading-list {
  .MuiTypography-root.MuiTypography-#{$key},
  .MuiTypography-root.MuiTypography-#{$key} svg {
    font-size: $val;
  }
}

// MuiTypography overriding
.MuiTypography-root * {
  font-family: "Inter", sans-serif;
  font-size: $font-size-default;
  color: $primary-color;
}

// MuiTypography overriding
// for caption variant
.MuiTypography-root.MuiTypography-caption {
  color: $primary-color;
  font-size: $font-size-small;
}

// MuiTypography overriding
// for subtitle2 variant
.MuiTypography-root.MuiTypography-subtitle1 {
  font-size: $font-size-default;
}

// MuiTypography overriding
// for subtitle2 variant
.MuiTypography-root.MuiTypography-subtitle2 {
  font-size: $font-size-default;
  font-weight: $font-weight-semi-bold;
}

// MuiTypography overriding
// for primary color
.MuiTypography-root.MuiTypography-colorPrimary,
.MuiTypography-root.MuiTypography-colorPrimary svg {
  color: $primary-color;
}

// MuiTypography overriding
// for primary color
.MuiTypography-root.MuiTypography-colorSuccess,
.MuiTypography-root.MuiTypography-colorSuccess svg {
  color: $tosca-color;
}

// MuiTypography overriding
// for secondary color
.MuiTypography-root.MuiTypography-colorSecondary,
.MuiTypography-root.MuiTypography-colorSecondary svg {
  color: $black-color;
}

// MuiTypography overriding
// for danger color
.MuiTypography-root.MuiTypography-colorDanger,
.MuiTypography-root.MuiTypography-colorDanger svg {
  color: $danger-6;
}

// MuiTypography overriding
// for caution color
.MuiTypography-root.MuiTypography-colorCaution,
.MuiTypography-root.MuiTypography-colorCaution svg {
  color: $danger-5;
}

// MuiTypography overriding
// for good color
.MuiTypography-root.MuiTypography-colorGood,
.MuiTypography-root.MuiTypography-colorGood svg {
  color: $success-6;
}

// MuiTypography overriding
// for excellent color
.MuiTypography-root.MuiTypography-colorExcellent,
.MuiTypography-root.MuiTypography-colorExcellent svg {
  color: $success-5;
}

// MuiTypography overriding
// for danger color
.MuiTypography-root.MuiTypography-colorMute,
.MuiTypography-root.MuiTypography-colorMute svg {
  color: $mute-color;
}

// MuiTypography overriding
// for danger color
.MuiTypography-root.MuiTypography-colorLink,
.MuiTypography-root.MuiTypography-colorLink svg {
  color: $blue-50;
}

.NppLink__wrapper {
  font-size: $font-size-default !important;
  color: $blue-50 !important;

  i {
    font-size: inherit !important;
    color: inherit !important;
  }
}

.NppLink__wrapper.NppLink__with-navigate {
  cursor: pointer;
}

.NppLink__wrapper.NppLink__gray-primary-hover {
  color: $mute-color-2;
  transition: $transition-default;

  &:hover {
    color: $bg-blue-color;
  }
}
