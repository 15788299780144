// ================================================================================================
//     File Name: datatables.scss
//     Description: Datatables pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: Stack - Responsive Admin Theme
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

table.dataTable, .dataTables_wrapper{
  border-spacing: 0px;
  thead, &.no-footer{
    th{
      border-bottom: transparent;
    }
  }
  tfoot{
    th{
      border-top: transparent;
    }
  }
}
.content-wrapper table.dataTable.dtr-inline.collapsed > tbody > tr {
  > {
    td:first-child:before, th:first-child:before {
      background-color: $primary;
      border-radius: 0;
      top: 10px;
      left: 10px;
      box-shadow: 0 0 2px #444;
    }
  }
  &.parent > {
    td:first-child:before, th:first-child:before {
      background-color: $danger;
    }
  }
}

.DTFC_LeftBodyLiner {
  top: -1px !important;
}

// Pagination

div.dataTables_wrapper {
  width: 100%;
  // margin: 0 auto;
  div{
    &.dataTables_paginate {
      .paginate_button{
        margin-left: 0;
        padding: 0;
        border: none !important;
        &:hover{
          background: none;
          border: 1px solid transparent;
        }
      }
      ul.pagination {
        margin: 10px 0;
        justify-content: flex-end;
      }
    }
    &.dataTables_length select, &.dataTables_filter input {
      width: auto;
      display: inline-block;
    }
    &.dataTables_length select{
      background: none;
      background-image: url('~images/pages/arrow-down.png');
      background-position: 86% 57%;
      background-repeat: no-repeat;
      background-size: 12px 10px;
    }
    .dataTables_scroll{
      .dataTables_scrollBody{
        >.table{
          margin: 0;
        }
        border-bottom: transparent;
      }
    }
    // show entries and pagination are on top or bottom
    .top, .bottom{
      .dataTables_info, .dataTables_filter, .dataTables_length{
        float: none;
      }
    }
  }
}
//css for vertical scroll

//css for highlight td

td.highlight {
  font-weight: bold;
  color: $primary;
  background-color: $white !important;
}

tr.group {
  background-color: #ededed !important;
  &:hover {
    background-color: #ededed !important;
  }
}

th {
  white-space: nowrap;
}

.toolbar {
  float: left;
}

.no-style {
  thead > tr > th, tfoot > tr > th {
    border-bottom: 1px solid #e3ebf3;
  }
}

table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable.no-footer {
  border-bottom-color: $border-color;
}

// CSS for Row selection (multiple rows)

.table-striped tbody tr {
  &.odd.selected, &.even.selected {
    background-color: $primary;
    color: #fff;
  }
}

table.dataTable tbody > tr > .selected {
  background-color: $primary;
  color: $white;
}

// CSS for Class names

a.dt-button {
  &.red {
    color: red;
  }
  &.orange {
    color: orange;
  }
  &.green {
    color: green;
  }
}

// -- Fixed Columns --

// CSS for CSS row sizing

.dataex-css-row tr {
  height: 55px;
}

// -- Focus cell custom styling --

.dataex-key-customstyling td.focus {
  outline: 1px solid #ac1212;
  outline-offset: -3px;
  background-color: #e3ebf3 !important;
}

// Reorder event
// Events log for reorder event & select events
.dt-events-log {
  border: 1px solid #888;
  background: #f7f7f7;
  padding: 1em;
  margin-bottom: 1em;
}

// Individual column searching (text inputs)

.dataTables_wrapper tfoot input {
  width: 100%;
  box-sizing: border-box;
}

td.details-control {
  background: url('~images/datatables/resources/details_open.png') no-repeat center center;
  cursor: pointer;
}

tr.shown td.details-control {
  background: url('~images/datatables/resources/details_close.png') no-repeat center center;
}
// Datatables fixed column scroll issue fixed for bootstrap 4
.DTFC_LeftBodyLiner, .DTFC_RightBodyLiner{
  background: white;
}


div.table-responsive>div.dataTables_wrapper>div.row {
  margin: 0;
}

// Datatables pagination
@media screen and (max-width : 600px){
  div.dataTables_wrapper {
    div.dataTables_paginate {
      ul.pagination{
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width : 1200px) and (min-width: 768px) {
  div.dataTables_wrapper {
    div.row:last-child{
      justify-content: space-between;
    }
    div.dataTables_paginate {
      float: right;
      ul.pagination{
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 767px){
  div.dataTables_wrapper {
    div.dataTables_filter#users-contacts_filter{
      text-align: right;
    }
    div.dataTables_info, div.dataTables_paginate{
      display: block;
      text-align: center;
      ul.pagination{
        justify-content: center !important;
      }
    }
  }
}


//datatable small screen scroll x
@include media-breakpoint-down(md) {
  .dataTables_wrapper table {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: auto;
  }
}
