/*=========================================================================================
	File Name: vertical-overlay-menu.scss
	Description: A overlay style vertical menu with show and hide support. It support 
	light & dark version, filpped layout, right side icons, native scroll and borders menu 
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/variables";
@import "../../../bootstrap/variables";

// Overrides user variable
@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

// Vertical Overlay Menu
//=========================
.vertical-overlay-menu {
  //Content expanded
  .content {
    @include main-menu-width(0);
  }
  //Navbar expanded
  .navbar {
    .navbar-header {
      @include menu-navbar-width($overlay-menu-width);
    }
    &.navbar-brand-center {
      // .navbar-container{
      // 	@include main-menu-width(0);
      // }
      .navbar-header {
        @include menu-navbar-width(auto);
      }
    }
  }
  // Main menu expanded
  .main-menu {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: width 0.25s, opacity 0.25s, transform 0.25s;
    width: $overlay-menu-width;
    left: -($overlay-menu-width);

    .navigation {
      .navigation-header {
        .feather.icon-minus {
          display: none;
        }
      }
      > li {
        > a {
          > i {
            font-size: 1.2rem;
            margin-right: 12px;
            float: left;
            &:before {
              transition: 200ms ease all;
            }
          }
        }
      }
      li {
        @include vertical-menu-has-sub-arrow(1rem);
      }
    }
    .main-menu-footer {
      bottom: 55px;
    }
    .main-menu-footer {
      width: $overlay-menu-width;
    }
  }
  &.menu-open {
    .main-menu {
      opacity: 1;
      transform: translate3d($overlay-menu-width, 0, 0);
      transition: width 0.25s, opacity 0.25s, transform 0.25s;
    }
  }
  &.menu-hide {
    .main-menu {
      @extend .main-menu;
    }
  }

  // Flipped menu open
  &.menu-flipped {
    // Main menu hidden on flipped
    .main-menu {
      right: -($overlay-menu-width);
      left: inherit;
    }
    //Navbar flipped
    .navbar {
      .navbar-container {
        @include vertical-flipped-menu($overlay-menu-width);
      }
      .navbar-header {
        float: right;
      }
    }
    &.menu-open {
      .main-menu {
        transform: translate3d(-$overlay-menu-width, 0, 0);
      }
    }
  }
  // For Fixed-footer
  footer {
    &.footer {
      &.fixed-bottom {
        z-index: 999;
      }
    }
  }
  //Box-layout
  &.vertical-overlay-menu {
    &.boxed-layout {
      &.menu-open {
        .main-menu {
          transform: translate3d($overlay-menu-width, 0, 0);
          margin-left: 7px;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .vertical-overlay-menu {
    .main-menu {
      .main-menu-footer {
        bottom: 0px;
      }
    }
  }
}

// Layout Boxed for down to sm screen
@media screen and (max-width: 575px) {
  // main-menu for Box-layout
  .vertical-overlay-menu {
    &.boxed-layout {
      &.menu-open {
        .main-menu {
          transform: translate3d($menu-expanded-width, 0, 0) !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}

// Collapsed menu RTL
[data-textdirection="rtl"] body.vertical-layout {
  &.vertical-overlay-menu.menu-collapsed {
    .main-menu {
      .navigation {
        > li > a {
          padding: 14px 16px !important;
        }
        > li.navigation-header .feather.icon-minus {
          padding: 12px 0px;
        }
      }
    }
  }
  &.vertical-overlay-menu {
    .main-menu {
      .navigation {
        li.has-sub {
          > a:not(.mm-next):after {
            content: "\f104";
          }
        }
      }
    }
  }
  .knob-center-icon {
    margin-left: -99px;
  }
}
