//@import "functions4.scss";
//@import "variables4.scss";

// Style conversion file, bootstrap to tabulator

//Main Theme Variables
$backgroundColor: transparent; //background color of tabulator
$borderColor: $table-border-color !default; //border to tabulator
$textSize: $font-size-base !default; //table text size

//header themeing
$headerBackgroundColor: $white !default; //border to tabulator
$headerSeperatorColor: $table-border-color !default; //header bottom seperator color

$cellPadding: $table-cell-padding !default; //padding round header

//column header arrows
$sortArrowActive: $blue-50 !default;
$sortArrowInactive: $primary-3 !default;

//row themeing
$rowBackgroundColor: $white !default; //table row background color
$rowAltBackgroundColor: $table-accent-bg !default; //table row background color
$rowBorderColor: $table-border-color !default; //table border color
$rowHoverBackground: $table-hover-bg !default; //row background color on hover

$rowSelectedBackground: $blue-20 !default; //row background color when selected
$rowSelectedBackgroundHover: $blue-20 !default; //row background color when selected and hovered


$editBoxColor: #1D68CD !default; //border color for edit boxes
$errorColor: #dd0000 !default; //error indication

//footer themeing
$footerBorderColor: $pagination-border-color !default; //footer border color
$footerSeperatorColor: $table-border-color !default; //footer bottom seperator color
$footerActiveColor: $blue-50 !default; //footer bottom active text color

$table-row-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), inset 0px -1px 0px rgba(0, 0, 0, 0.05);
$tableBackgroundColor: transparent;
$pagination-disabled-bg: transparent;

// Loading
$loadingBackground: rgba(255, 255, 255, .8);

//Tabulator Containing Element
.tabulator {
  position: relative;
  background-color: $backgroundColor;
  overflow: hidden;
  font-size: $textSize;
  text-align: left;
  width: 100%;
  max-width: 100%;
  // margin-bottom: $line-height-computed;

  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);

  &[tabulator-layout="fitDataFill"] {
    .tabulator-tableHolder {
      .tabulator-table {
        min-width: 100%;
      }
    }
  }

  &[tabulator-layout="fitDataTable"] {
    display: inline-block;
  }

  &.tabulator-block-select {
    user-select: none;
  }

  //column header containing element
  .tabulator-header {
    position: relative;
    box-sizing: border-box;

    width: 100%;

    background-color: $headerBackgroundColor;
    border-radius: $border-radius;
    box-shadow: $table-row-box-shadow;
    margin-top: .5rem;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    &.tabulator-header-hidden {
      display: none;
    }

    //individual column header element
    .tabulator-col {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      background-color: $headerBackgroundColor;
      text-align: left;
      vertical-align: bottom;
      overflow: hidden;

      &.tabulator-moving {
        position: absolute;
        border: 1px solid $headerSeperatorColor;
        background: darken($headerBackgroundColor, 10%);
        pointer-events: none;
      }

      //hold content of column header
      .tabulator-col-content {
        box-sizing: border-box;
        position: relative;
        padding: $cellPadding;

        //header menu button
        .tabulator-header-menu-button {
          padding: 0 8px;

          &:hover {
            cursor: pointer;
            opacity: .6;
          }
        }

        //hold title of column header
        .tabulator-col-title {
          box-sizing: border-box;
          width: 100%;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: bottom;

          //element to hold title editor
          .tabulator-title-editor {
            box-sizing: border-box;
            width: 100%;

            border: 1px solid #999;

            padding: 1px;

            background: #fff;
          }
        }

        //column sorter arrow
        .tabulator-arrow {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 8px;
          vertical-align: middle;
          transform: translateY(-50%);
          color: $sortArrowInactive;

          &:before {
            font-family: "dropsuite-solid";
            font-style: normal;
            font-weight: normal;
            speak: never;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\e820';
          }
        }

      }

      //complex header column group
      &.tabulator-col-group {

        //gelement to hold sub columns in column group
        .tabulator-col-group-cols {
          position: relative;
          display: flex;

          border-top: 1px solid $borderColor;
          overflow: hidden;

          margin-right: -1px;
        }
      }


      //hide left resize handle on first column
      &:first-child {
        .tabulator-col-resize-handle.prev {
          display: none;
        }
      }

      //header filter containing element
      .tabulator-header-filter {
        position: relative;
        box-sizing: border-box;
        margin-top: 2px;
        width: 100%;
        text-align: center;

        //styling adjustment for inbuilt editors
        textarea {
          height: auto !important;
        }

        svg {
          margin-top: 3px;
        }

        input {
          &::-ms-clear {
            width: 0;
            height: 0;
          }
        }
      }


      //styling child elements for sortable columns
      &.tabulator-sortable {
        .tabulator-col-title {
          padding-right: 25px;
        }

        &:hover {
          cursor: pointer;
          background-color: darken($headerBackgroundColor, 10%);
        }

        &[aria-sort="none"] {
          .tabulator-col-content .tabulator-arrow {
          }
        }

        &[aria-sort="asc"] {
          .tabulator-col-content .tabulator-arrow {
            color: $blue-50;

            &:before {
              content: '\e81f';
            }
          }
        }

        &[aria-sort="desc"] {
          .tabulator-col-content .tabulator-arrow {
            color: $blue-50;

            &:before {
              content: '\e81e';
            }
          }
        }
      }

      &.tabulator-col-vertical {
        .tabulator-col-content {
          .tabulator-col-title {
            writing-mode: vertical-rl;
            text-orientation: mixed;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.tabulator-col-vertical-flip {
          .tabulator-col-title {
            transform: rotate(180deg);
          }
        }

        &.tabulator-sortable {
          .tabulator-col-title {
            padding-right: 0;
            padding-top: 20px;
          }

          &.tabulator-col-vertical-flip {
            .tabulator-col-title {
              padding-right: 0;
              padding-bottom: 20px;
            }

          }

          .tabulator-arrow {
            right: calc(50% - 6px);
          }
        }
      }

    }

    .tabulator-frozen {
      display: inline-block;
      position: absolute;

      // background-color: inherit;

      z-index: 10;

      &.tabulator-frozen-left {
        border-right: 2px solid $rowBorderColor;
      }

      &.tabulator-frozen-right {
        border-left: 2px solid $rowBorderColor;
      }
    }

    .tabulator-calcs-holder {
      box-sizing: border-box;
      width: 100%;

      background: lighten($headerBackgroundColor, 5%) !important;

      .tabulator-row {
        background: lighten($headerBackgroundColor, 5%) !important;

        .tabulator-col-resize-handle {
          display: none;
        }
      }

      border-top: 1px solid $rowBorderColor;
      border-bottom: 1px solid $headerSeperatorColor;

      overflow: hidden;
    }

    .tabulator-frozen-rows-holder {
      min-width: 600%;

      &:empty {
        display: none;
      }
    }
  }


  //scrolling element to hold table
  .tabulator-tableHolder {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &:focus {
      outline: none;
    }

    //default placeholder element
    .tabulator-placeholder {
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &[tabulator-render-mode="virtual"] {
        min-height: 100%;
        min-width: 100%;
      }

      width: 100%;

      span {
        display: inline-block;

        margin: 0 auto;
        padding: 10px;

        color: #000;
        font-weight: $font-weight-bold;
        font-size: 20px;
      }
    }

    //element to hold table rows
    .tabulator-table {
      position: relative;
      display: inline-block;
      background-color: $tableBackgroundColor;
      white-space: nowrap;
      overflow: visible;

      .tabulator-row {
        &.tabulator-calcs {
          font-weight: $font-weight-bold;
          background: darken($rowAltBackgroundColor, 5%) !important;

          &.tabulator-calcs-top {
            border-bottom: 2px solid $rowBorderColor;
          }

          &.tabulator-calcs-bottom {
            border-top: 2px solid $rowBorderColor;
          }
        }
      }
    }
  }


  //column resize handles
  .tabulator-col-resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;

    &.prev {
      left: 0;
      right: auto;
    }

    &:hover {
      cursor: ew-resize;
    }
  }


  //footer element
  .tabulator-footer {
    padding: 1rem .5rem;
    text-align: right;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    user-select: none;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    .tabulator-calcs-holder {
      box-sizing: border-box;
      width: calc(100% + 20px);
      margin: -5px -10px 5px -10px;

      text-align: left;

      background: lighten($backgroundColor, 5%) !important;

      .tabulator-row {
        background: lighten($backgroundColor, 5%) !important;

        .tabulator-col-resize-handle {
          display: none;
        }
      }

      border-bottom: 1px solid $rowBorderColor;
      border-top: 1px solid $rowBorderColor;

      overflow: hidden;

      &:only-child {
        margin-bottom: -5px;
        border-bottom: none;
      }
    }

    //pagination container element
    .tabulator-paginator {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
    }

    //page size select element
    .tabulator-page-size {
      display: inline-block;

      margin: 0 1rem 0 5px;
      padding: 2px 5px;

      border: 1px solid $footerBorderColor;
      border-radius: 3px;
    }

    //pagination container element
    .tabulator-pages {
      // margin:0 7px;
    }

    //pagination button
    .tabulator-page {
      color: $footerActiveColor;
      display: inline-block;

      margin: 5px 0 0;
      padding: 5px 12px;

      border: none;
      border-radius: $border-radius;

      background-color: transparent;

      &[data-page="first"] {
      }

      &[data-page="last"] {
      }

      &[data-page="prev"] {
        &:before {
          font-family: "dropsuite-line";
          font-style: normal;
          font-weight: normal;
          content: '\e80b';
        }
      }

      &[data-page="next"] {
        &:after {
          font-family: "dropsuite-line";
          font-style: normal;
          font-weight: normal;
          content: '\e80c';
        }
      }

      &.active {
        background-color: $pagination-active-bg;
        color: $footerActiveColor;
      }

      &:disabled {
        visibility: hidden;
        border-color: $pagination-disabled-border-color;
        background: $pagination-disabled-bg;
        color: $pagination-disabled-color;
      }

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
          border-color: $pagination-hover-border-color;
          background: $pagination-hover-bg;
          color: $pagination-hover-color;
        }
      }
    }
  }

  //holding div that contains loader and covers tabulator element to prevent interaction
  .tabulator-loader {
    position: absolute;
    display: flex;
    align-items: center;

    top: 0;
    left: 0;
    z-index: 100;

    height: 100%;
    width: 100%;
    background: $loadingBackground;
    text-align: center;

    //loading message element
    .tabulator-loader-msg {
      display: inline-block;

      margin: 0 auto;
      padding: 10px 20px;

      //loading message
      &.tabulator-loading {
      }

      //error message
      &.tabulator-error {
        color: #590000;
      }
    }
  }


  //Bootstrap theming classes

  &.thead-dark {
    .tabulator-header {
      border-color: $table-dark-border-color;
      background-color: $table-dark-bg;
      color: $table-dark-color;

      .tabulator-col {
        border-color: $table-dark-border-color;
        background-color: $table-dark-bg;
        color: $table-dark-color;
      }
    }
  }

  &.table-dark {
    background-color: $table-dark-bg;

    &:not(.thead-light) .tabulator-header {
      border-color: $table-dark-border-color;
      background-color: $table-dark-bg;
      color: $table-dark-color;

      .tabulator-col {
        border-color: $table-dark-border-color;
        background-color: $table-dark-bg;
        color: $table-dark-color;
      }
    }

    .tabulator-tableHolder {
      color: $table-dark-color;
    }


    .tabulator-row {
      border-color: $table-dark-border-color;
      background-color: $table-dark-bg;
      color: $table-dark-color;

      &:hover {
        background-color: $table-dark-border-color;

        .tabulator-cell {
          background-color: $table-dark-hover-bg;
        }
      }

      &.tabulator-selected {
        background-color: $rowSelectedBackground;
      }
    }
  }

  &.table-striped {
    &:not(.table-dark) {
      .tabulator-row {
        &:nth-child(even) {
          background-color: $rowAltBackgroundColor;

          &.tabulator-selected {
            background-color: $rowSelectedBackground;
          }

          &.tabulator-selectable:hover {
            background-color: $rowHoverBackground;
            cursor: pointer;
          }

          &.tabulator-selected:hover {
            background-color: $rowSelectedBackgroundHover;
            cursor: pointer;
          }
        }
      }
    }

    &.table-dark {
      .tabulator-row {
        &:nth-child(even) {
          .tabulator-cell {
            background-color: $table-dark-accent-bg;
          }
        }
      }
    }
  }

  &.table-bordered {
    border: 1px solid $borderColor;

    .tabulator-header {
      .tabulator-col {
        border-right: 1px solid $borderColor;
      }
    }

    .tabulator-tableHolder {
      .tabulator-table {
        .tabulator-row {
          .tabulator-cell {
            border-right: 1px solid $borderColor;
          }
        }
      }
    }
  }


  &.table-borderless {
    .tabulator-header {
      border: none;
    }

    .tabulator-row {
      border: none;
    }
  }

  &.table-sm {
    .tabulator-header {
      .tabulator-col {
        .tabulator-col-content {
          padding: $table-cell-padding-sm !important;
        }
      }
    }

    .tabulator-tableHolder {
      .tabulator-table {
        .tabulator-row {
          min-height: $textSize + ($table-cell-padding-sm * 2);

          .tabulator-cell {
            padding: $table-cell-padding-sm !important;
          }
        }
      }
    }
  }


  //row colors
  .tabulator-tableHolder {
    .tabulator-table {
      .tabulator-row {
        &.table-primary {
          background: theme-color-level("primary", -9) !important;
        }

        &.table-secondary {
          background: theme-color-level("secondary", -9) !important;
        }

        &.table-success {
          background: theme-color-level("success", -9) !important;
        }

        &.table-info {
          background: theme-color-level("info", -9) !important;
        }

        &.table-warning {
          background: theme-color-level("warning", -9) !important;
        }

        &.table-danger {
          background: theme-color-level("danger", -9) !important;
        }

        &.table-light {
          background: theme-color-level("light", -9) !important;
        }

        &.table-dark {
          background: theme-color-level("dark", -9) !important;
        }

        &.table-active {
          background: $table-active-bg !important;
        }

        &.bg-primary {
          background: theme-color-level("primary", 0) !important;
        }

        &.bg-secondary {
          background: theme-color-level("secondary", 0) !important;
        }

        &.bg-success {
          background: theme-color-level("success", 0) !important;
        }

        &.bg-info {
          background: theme-color-level("info", 0) !important;
        }

        &.bg-warning {
          background: theme-color-level("warning", 0) !important;
        }

        &.bg-danger {
          background: theme-color-level("danger", 0) !important;
        }

        &.bg-light {
          background: theme-color-level("light", 0) !important;
        }

        &.bg-dark {
          background: theme-color-level("dark", 0) !important;
        }

        &.bg-active {
          background: $table-active-bg !important;
        }

        &.tabulator-unselectable {
          color: #97A3B8;
        }

        .tabulator-cell {
          &.table-primary {
            background: theme-color-level("primary", -9) !important;
          }

          &.table-secondary {
            background: theme-color-level("secondary", -9) !important;
          }

          &.table-success {
            background: theme-color-level("success", -9) !important;
          }

          &.table-info {
            background: theme-color-level("info", -9) !important;
          }

          &.table-warning {
            background: theme-color-level("warning", -9) !important;
          }

          &.table-danger {
            background: theme-color-level("danger", -9) !important;
          }

          &.table-light {
            background: theme-color-level("light", -9) !important;
          }

          &.table-dark {
            background: theme-color-level("dark", -9) !important;
          }

          &.table-active {
            background: $table-active-bg !important;
          }

          &.bg-primary {
            background: theme-color-level("primary", 0) !important;
          }

          &.bg-secondary {
            background: theme-color-level("secondary", 0) !important;
          }

          &.bg-success {
            background: theme-color-level("success", 0) !important;
          }

          &.bg-info {
            background: theme-color-level("info", 0) !important;
          }

          &.bg-warning {
            background: theme-color-level("warning", 0) !important;
          }

          &.bg-danger {
            background: theme-color-level("danger", 0) !important;
          }

          &.bg-light {
            background: theme-color-level("light", 0) !important;
          }

          &.bg-dark {
            background: theme-color-level("dark", 0) !important;
          }

          &.bg-active {
            background: $table-active-bg !important;
          }
        }
      }
    }
  }

}

//row element
.tabulator-row {
  position: relative;
  box-sizing: border-box;

  min-height: $textSize + ($cellPadding * 2);
  background-color: $rowBackgroundColor;
  border-radius: $border-radius;
  box-shadow: $table-row-box-shadow;
  margin-bottom: .5rem;
  margin-top: .5rem;

  &.tabulator-selectable:hover {
    background-color: $rowHoverBackground;
    cursor: pointer;
  }

  &.tabulator-selected {
    background-color: $rowSelectedBackground;
  }

  &.tabulator-selected:hover {
    background-color: $rowSelectedBackgroundHover;
    cursor: pointer;
  }

  &.tabulator-moving {
    position: absolute;

    border-top: 1px solid $rowBorderColor;
    border-bottom: 1px solid $rowBorderColor;

    pointer-events: none !important;
    z-index: 15;
  }

  //row resize handles
  .tabulator-row-resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;

    &.prev {
      top: 0;
      bottom: auto;
    }

    &:hover {
      cursor: ns-resize;
    }
  }

  .tabulator-frozen {
    display: inline-block;
    position: absolute;

    background-color: inherit;

    z-index: 10;

    &.tabulator-frozen-left {
      border-right: 2px solid $rowBorderColor;
    }

    &.tabulator-frozen-right {
      border-left: 2px solid $rowBorderColor;
    }
  }

  .tabulator-responsive-collapse {
    box-sizing: border-box;

    padding: 5px;

    border-top: 1px solid $rowBorderColor;
    border-bottom: 1px solid $rowBorderColor;

    &:empty {
      display: none;
    }

    table {
      font-size: $textSize;

      tr {
        td {
          position: relative;

          &:first-of-type {
            padding-right: 10px;
          }
        }
      }
    }
  }

  //cell element
  .tabulator-cell {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: $cellPadding;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-of-type {
      border-right: none;
    }

    &.tabulator-editing {
      border: 1px solid $editBoxColor;
      padding: 0;

      input, select {
        border: 1px;
        background: transparent;
      }
    }

    &.tabulator-validation-fail {
      border: 1px solid $errorColor;

      input, select {
        border: 1px;
        background: transparent;

        color: $errorColor;
      }
    }

    //hide left resize handle on first column
    &:first-child {
      .tabulator-col-resize-handle.prev {
        display: none;
      }
    }

    //movable row handle
    &.tabulator-row-handle {

      display: inline-flex;
      align-items: center;

      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;

      //handle holder
      .tabulator-row-handle-box {
        width: 80%;

        //Hamburger element
        .tabulator-row-handle-bar {
          width: 100%;
          height: 3px;
          margin-top: 2px;
          background: #666;
        }
      }
    }

    .tabulator-data-tree-branch {
      display: inline-block;
      vertical-align: middle;

      height: 9px;
      width: 7px;

      margin-top: -9px;
      margin-right: 5px;

      border-bottom-left-radius: 1px;

      border-left: 2px solid $rowBorderColor;
      border-bottom: 2px solid $rowBorderColor;
    }

    .tabulator-data-tree-control {

      display: inline-flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;

      height: 11px;
      width: 11px;

      margin-right: 5px;

      border: 1px solid #ccc;
      border-radius: 2px;
      background: rgba(0, 0, 0, .1);

      overflow: hidden;

      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, .2);
      }

      .tabulator-data-tree-control-collapse {
        display: inline-block;
        position: relative;

        height: 7px;
        width: 1px;

        background: transparent;

        &:after {
          position: absolute;
          content: "";
          left: -3px;
          top: 3px;

          height: 1px;
          width: 7px;

          background: #ccc;
        }
      }

      .tabulator-data-tree-control-expand {
        display: inline-block;
        position: relative;

        height: 7px;
        width: 1px;

        background: #ccc;

        &:after {
          position: absolute;
          content: "";
          left: -3px;
          top: 3px;

          height: 1px;
          width: 7px;

          background: #ccc;
        }
      }

    }

    .tabulator-responsive-collapse-toggle {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;

      height: 15px;
      width: 15px;

      border-radius: 20px;
      background: #666;

      color: $rowBackgroundColor;
      font-weight: $font-weight-bold;
      font-size: 1.1em;

      &:hover {
        opacity: .7;
      }

      &.open {
        .tabulator-responsive-collapse-toggle-close {
          display: initial;
        }

        .tabulator-responsive-collapse-toggle-open {
          display: none;
        }
      }

      .tabulator-responsive-collapse-toggle-close {
        display: none;
      }
    }

    .tabulator-traffic-light {
      display: inline-block;
      height: 14px;
      width: 14px;

      border-radius: 14px;
    }
  }

  //row grouping element
  &.tabulator-group {

    box-sizing: border-box;
    border-bottom: 1px solid #999;
    border-right: 1px solid $rowBorderColor;
    border-top: 1px solid #999;
    padding: 5px;
    padding-left: 10px;
    background: #fafafa;
    font-weight: $font-weight-bold;

    min-width: 100%;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .1);
    }

    &.tabulator-group-visible {
      .tabulator-arrow {
        margin-right: 10px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $sortArrowActive;
        border-bottom: 0;
      }
    }

    &.tabulator-group-level-1 {
      padding-left: 30px;
    }

    &.tabulator-group-level-2 {
      padding-left: 50px;
    }

    &.tabulator-group-level-3 {
      padding-left: 70px;
    }

    &.tabulator-group-level-4 {
      padding-left: 90px;
    }

    &.tabulator-group-level-5 {
      padding-left: 110px;
    }

    .tabulator-group-toggle {
      display: inline-block;
    }

    //sorting arrow
    .tabulator-arrow {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 16px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 0;
      border-left: 6px solid $sortArrowActive;
      vertical-align: middle;
    }

    span {
      margin-left: 10px;
      color: #666;
    }
  }
}

.tabulator-menu {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;

  background: $rowBackgroundColor;
  border: 1px solid $rowBorderColor;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);

  font-size: $textSize;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  z-index: 10000;

  .tabulator-menu-item {

    padding: 5px 10px;

    user-select: none;

    &.tabulator-menu-item-disabled {
      opacity: .5;
    }

    &:not(.tabulator-menu-item-disabled):hover {
      cursor: pointer;
      background: $rowAltBackgroundColor;
    }
  }

  .tabulator-menu-separator {
    border-top: 1px solid $rowBorderColor;
  }
}

.tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;

  max-height: 200px;

  background: $headerBackgroundColor;
  border: 1px solid $rowBorderColor;

  font-size: $textSize;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  z-index: 10000;

  .tabulator-edit-select-list-item {
    padding: 4px;

    &.active {
      color: $headerBackgroundColor;
      background: $editBoxColor;

      &.focused {
        outline: 1px solid rgba($headerBackgroundColor, .5);
      }
    }

    &.focused {
      outline: 1px solid $editBoxColor;
    }


    &:hover {
      cursor: pointer;

      color: $headerBackgroundColor;
      background: $editBoxColor;
    }
  }

  .tabulator-edit-select-list-notice {
    padding: 4px;

    text-align: center;
  }

  .tabulator-edit-select-list-group {
    border-bottom: 1px solid $rowBorderColor;

    padding: 4px;
    padding-top: 6px;

    font-weight: $font-weight-bold;
  }
}

// Table print styling

.tabulator-print-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10000;
}

body.tabulator-print-fullscreen-hide > *:not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;

  //row grouping element
  .tabulator-print-table-group {

    box-sizing: border-box;
    border-bottom: 1px solid #999;
    border-right: 1px solid $rowBorderColor;
    border-top: 1px solid #999;
    padding: 5px;
    padding-left: 10px;
    background: #fafafa;
    font-weight: $font-weight-bold;

    min-width: 100%;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .1);
    }

    &.tabulator-group-visible {
      .tabulator-arrow {
        margin-right: 10px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $sortArrowActive;
        border-bottom: 0;
      }
    }

    &.tabulator-group-level-1 {
      td {
        padding-left: 30px !important;
      }
    }

    &.tabulator-group-level-2 {
      td {
        padding-left: 50px !important;
      }
    }

    &.tabulator-group-level-3 {
      td {
        padding-left: 70px !important;
      }
    }

    &.tabulator-group-level-4 {
      td {
        padding-left: 90px !important;
      }
    }

    &.tabulator-group-level-5 {
      td {
        padding-left: 110px !important;
      }
    }

    .tabulator-group-toggle {
      display: inline-block;
    }

    //sorting arrow
    .tabulator-arrow {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 16px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 0;
      border-left: 6px solid $sortArrowActive;
      vertical-align: middle;
    }

    span {
      margin-left: 10px;
      color: #666;
    }
  }

  .tabulator-data-tree-branch {
    display: inline-block;
    vertical-align: middle;

    height: 9px;
    width: 7px;

    margin-top: -9px;
    margin-right: 5px;

    border-bottom-left-radius: 1px;

    border-left: 2px solid $rowBorderColor;
    border-bottom: 2px solid $rowBorderColor;
  }

  .tabulator-data-tree-control {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    height: 11px;
    width: 11px;

    margin-right: 5px;

    border: 1px solid #ccc;
    border-radius: 2px;
    background: rgba(0, 0, 0, .1);

    overflow: hidden;

    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, .2);
    }

    .tabulator-data-tree-control-collapse {
      display: inline-block;
      position: relative;

      height: 7px;
      width: 1px;

      background: transparent;

      &:after {
        position: absolute;
        content: "";
        left: -3px;
        top: 3px;

        height: 1px;
        width: 7px;

        background: #ccc;
      }
    }

    .tabulator-data-tree-control-expand {
      display: inline-block;
      position: relative;

      height: 7px;
      width: 1px;

      background: #ccc;

      &:after {
        position: absolute;
        content: "";
        left: -3px;
        top: 3px;

        height: 1px;
        width: 7px;

        background: #ccc;
      }
    }
  }
}

.simple-datatable {
  .tabulator-row {
    margin-bottom: 0;
    margin-top: 0;
  }

  .header-actions {
    position: absolute;
    left: 40px;
    top: 0;
    width: 100%;
    background-color: white;
    height: 100%;

    .action {
      display: inline-block;
      padding: 0.75rem;
    }
  }

  .footer-actions {
    position: absolute;
    text-align: left;
    padding-top: .75rem;

    .action {
      display: inline-block;
      padding: 0 0.75rem;
    }
  }
}