.api-settings-card{
  h1 {
    margin-bottom: 1.063rem;
  }

  .api-side-bar-menu{
    background-color: #F9F9FB;
    padding: 0;

    @media (min-width: 768px) { 
      min-height: 43.75rem;
    }

    .title{
      padding: 1.5rem 0 .75rem 1.25rem;
      font-weight: $font-weight-bold;
      line-height: 1.2rem;
      color: $gray-950;
    }

    .api-menu-list-wrapper{
      .api-menu-list{
        padding: .625rem 1.25rem .625rem 1.25rem;
        color: $blue-50;
        font-size: $font-size-sm;
        line-height: 1.25rem;
        cursor: pointer;

        &.active{
          background-color: #CCE3F9;
        }

        &:hover:not(.active){
          background-color: #F2F4F6;
        }
      }
    }
  }
  .display-token {
    width: 250px;
    border: none;
    background: none;
    color: #42526E;
  }
  .password-open, .copy-token{
    cursor: pointer;
  }

  .api-settings-content{
    padding: 1.5rem;
    font-size: $font-size-sm;
    line-height: 1.25rem;
    background-color: $white;

    h2.title{
      font-size: $font-size-lg;
      color: $gray-950;
      font-weight: $font-weight-bold;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    h4.sub-title{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .sub-title-explanation{
      border-left: 2px solid $blue-50;
      padding-left: 1rem;
    }

    .description{
      font-size: $font-size-sm;
      line-height: 1.575rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    /* API INFORMATION PART */
    .credential-row{
      padding: 0.5rem 0.5rem 0.5rem 0;
      margin: auto 0 auto 0;

      &.box-shadow-bottom{
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
      }
  
      .title{
        padding-left: 0;
        font-weight: 500;
        color: $gray-950;
      }  

      .value{
        font-weight: $font-weight-normal;
        color: $gray-750;
      }
    }

    /* OVERVIEW PART */
    p.overview-text{
      font-size: $font-size-sm;
      color: $gray-750;
      line-height: 1.563rem;
    }

    /* GET STARTED PART */
    .table th, .table td{
      padding: 1rem 0 1rem 0.5rem;
    }

    .code-wrapper{
      background-color: #2D3337;
      border-radius: $border-radius;
      color: $white;
      padding: 0.25rem;

      .space-up-and-bottom{
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .code-bg{
          background: #E5F3FF;
          border: 1px solid $blue-50;
          box-sizing: border-box;
          border-radius: 1.5rem;
          width: 2.563rem;
          height: 1.188rem;
          margin: 0 0 0 0.5rem;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          
          .code-http-method{
            color: #17324B;
            text-align: center;
            position: relative;
            bottom: 0.313rem;
            font-size: .75rem;
          }
        }
      }
    }

    /* Browsable PART */

    /* Swagger Override */
    .swagger-ui{
      /* Hide title for Swagger*/
      .information-container{
          display: none !important;
        }
  
      .wrapper{
        padding: 0;
    
        .block{
          padding: 0;
    
          .opblock-tag{
            padding: 0;
            font-size: $font-size-base;
            box-shadow: inset 0px -1px 0px #F2F4F6;
            border-bottom: 0;
            margin-bottom: .75rem;
          }
    
          .opblock-summary{
            font-size: $font-size-sm;
            padding: .938rem 1rem .938rem 1rem;
            
            .opblock-summary-path{
              max-width: inherit;
            }

            .opblock-summary-method{
              padding: 0;
              font-size: 0.5rem;
              min-width: 2.313rem;
              height: .875rem;
              background: #E5F3FF;
              color: $blue-50;
              border: 1px solid $blue-50;
              box-sizing: border-box;
              border-radius: $border-radius;
              line-height: .625rem;
              padding: .125rem;
            }
          }
        }
      }
    }
  }    
}
