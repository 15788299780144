.ModalDownloadCSVCardBlock__modal-content {
    display: flex;
    flex-direction: column;
    gap: 1.143rem;
  
    .ModalDownloadCSVCardBlock__modal-content-list {
      display: flex;
      flex-direction: column;
  
      .ModalDownloadCSVCardBlock__modal-content-list-item {
        display: flex;
        justify-content: space-between;
        padding: 0.786rem 0rem;
        box-shadow: $inset-shadow;
  
        .MuiTypography-root {
          &:nth-of-type(1) {
            font-weight: $font-weight-bold;
            color: $black-color-4;
          }
  
          &:nth-of-type(1) {
            color: $black-color-4;
            color: $mute-color;
          }
        }
      }
    }
  }