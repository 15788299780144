#group-list {
  .simple-datatable {
    .tabulator-header {
    }

    .tabulator-row {
      box-shadow: none;

      &.tabulator-selected {
        .tabulator-cell .highlighted {
          color: $blue-50;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}