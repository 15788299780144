.FilterDropdownBlock__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $border-form-color;
    width: 100%;

    .MuiButtonBase-root {
        padding-left: 0rem;
    }
}

.MuiTypography-root.FilterDropdownBlock__item-list {
    color: $primary-10;
    font-weight: $font-weight-bold;
}

.FilterDropdownBlock__header {
    display: flex;
    padding: 0rem 1rem 0rem;
    flex-direction: column;
}

.MuiFormControl-root.FilterDropdownBlock__search {
    display: flex;
    align-items: center;

    &>div {
        padding-bottom: 1rem;
        border-bottom: 1px solid $border-form-color;
    }
}

.FilterDropdownBlock__checkbox-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $border-form-color;
    width: 100%;

    .MuiButtonBase-root {
        padding-left: 0rem;
    }
}


.FilterDropdownBlock__footer {
    padding: 1rem 1rem 0rem;
    
    .FilterDropdownBlock__line {
        border-top: 1px solid $border-form-color;
        padding-top: 1rem;
    }

    .MuiButtonBase-root.FilterDropdownBlock_Button {
        width: 100%;
        padding: .71rem 1.14rem;
        height: inherit;
    }
}